import Vue from "vue";
import { resolveRequestError } from "../utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class RoomService {

    static async createRoomType(payload) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_ROOM_TYPE, payload);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async updateRoomType(id, payload) {
        try {
            const { data } = await Vue.http.put(ENDPOINTS.UPDATE_ROOM_TYPE(id), payload);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createRoom(payload) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_ROOM, payload);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async updateRoom(id, payload) {
        try {
            const { data } = await Vue.http.put(ENDPOINTS.UPDATE_ROOM(id), payload);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchRoomTypes(payload) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.ROOM_TYPES, payload);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getSingleRoomTypes(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.SINGLE_ROOM_TYPE(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchRooms(filter_arr) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.ROOMS, filter_arr);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }
}