import Vue from "vue";
import { resolveRequestError } from "../utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class UserService {

    /**
     *  Fetch profile
     *
     * @async
     * @return {Object} 
     */
    static async getProfile() {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.PROFILE);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    


}