<template>
    <div>
        <TopBar title="Restaurant" />

        <search-view 
            :has-filter="true"
            :filter-params="filters"
            :is-loading="false"
            :has-search-field="true"
            @submit="runFilter" />

        <order-table ref="orderTable" product_type="restaurant" />
    </div>
</template>

<script>

    import TopBar from "../../components/TopBar.vue";
    import SearchView from "../../components/SearchView.vue";
    import OrderTable from "./components/OrderTable";

    export default {
        name:'restaurant',
        data() {
            return {
                filters: [
                    { name: "Order Status", field_name: "status", options: ["pending", "packaging", "en-route", "delivered"] },
                ],
            }
        },
        components: {
            TopBar,
            SearchView,
            OrderTable
        },
        methods: {
            runFilter(filter_params) {
                this.$refs.orderTable.filter(filter_params);
            }
        }
    };
    
</script>

<style scoped></style>