<template>

	<b-container class="pt-5">
        <b-row>
            <b-col class="text-center mt-5 mb-3">
            <div class="logo pt-4 text-center">
					<h1 class="text-primary font-weight-bold"><b>Dwella</b></h1>
					<div style="margin-top:-15px" class="text-dark">HOTEL MANAGEMENT &amp; AUTOMATION</div>
				</div>
            </b-col>
        </b-row>

		<slot />
		
	</b-container>

</template>

<script>

	export default {
		data() {
			return {
			logo: require("../assets/logo.png"),
			};
		}
	};

</script>

<style scoped>

	.logo {
        font-size: 7px;
    }

    .logo h1 {
        text-shadow: 0px 0px 15px #ccc;
    }

	.btm-pattern {
		height:10px;
		width: 100%;
		background-image: url(../assets/images/bottompattern.png);
		background-size: contain;
		position:fixed;
		bottom:0;
		left:0;
	}

</style>