<template>
  <div>
    <TopBar title="Bookings" />

    <search-view
      :has-filter="true"
      :filter-params="filters"
      :is-loading="loading"
      :has-search-field="true"
      @submit="runFilter"
    />

    <div class="mt-1 w-100">
      <bookings-table ref="bookingsTable" />
    </div>

  </div>
</template>

<script>
import TopBar from "../../components/TopBar.vue";
import SearchView from "../../components/SearchView.vue";
import BookingsTable from "./components/BookingsTable.vue";
import { RoomService } from "@/services";

export default {
  name: "bookings",
  data() {
    return {
      filters: [
        { name: "Room Type", field_name: "room_type_id", options: this.room_types },
        { name: "Status", field_name: "status", options: ["checked_in", "checked_out", "pending", "cancelled"] },
      ],
      loading: false,
    };
  },
  components: {
    TopBar,
    SearchView,
    BookingsTable,
  },
  methods: {
    runFilter(filter_params) {
      this.$refs.bookingsTable.filter(filter_params);
    },
    async fetchRoomTypes() {
      let {data} = await RoomService.fetchRoomTypes();
      this.filters[0].options = data.room_types;
    },
  },
  created() {
    this.fetchRoomTypes();
  },
};
</script>

<style scoped>
.b-icon {
  background-color: rgba(243, 232, 249, 0.1);
}
</style>
