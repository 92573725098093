import Vue from "vue";
import { resolveRequestError } from "../utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class BookingService {

    static async fetchBookings(filter_arr) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.BOOKINGS, filter_arr);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createBooking(booking_data) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_BOOKING, booking_data);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async updateBooking(id, booking_data) {
        try {
            const { data } = await Vue.http.put(ENDPOINTS.UPDATE_BOOKING(id), booking_data);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async cancelBooking(id) {
        try {
            const { data } = await Vue.http.patch(ENDPOINTS.CANCEL_BOOKING(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchBookingDetails(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.BOOKING_DETAILS(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async assignRoom(id, form) {
        try {
            const { data } = await Vue.http.patch(ENDPOINTS.ASSIGN_ROOM(id), form);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchAvailableRooms(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.FETCH_AVAILABLE_ROOMS(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }
}