import store from '@/store';
import Vue from 'vue';

store.subscribe((mutation) => {
    switch(mutation.type) {
        case "auth/SET_TOKEN":
            if(mutation.payload) {
                Vue.http.defaults.headers.common["Authorization"] = `Bearer ${mutation.payload}`;
                localStorage.setItem('authToken', mutation.payload);
            } else {
                Vue.http.defaults.headers.common["Authorization"] = null;
                localStorage.removeItem('authToken');
            }
            break;
    }
})
