<template>

        <div class="app-nav text-left" cols="12">
            
            <div class="logo pt-4 bg-light text-center">
                <h1 class="text-primary font-weight-bold"><b>Dwella</b></h1>
                <div style="margin-top:-15px" class="text-dark">HOTEL MANAGEMENT &amp; AUTOMATION</div>

                <div class="mt-4 pt-2 pb-2 coy bg-white text-uppercase">{{ user.company.name }}</div>
            </div>
        
            <div class="p-3 mt-2">
                <ul class="list-unstyled mt-0">
                    <li v-for="menu in links" :key="menu.name" class="pb-2" v-show="checkPermission(menu.permission)">
                        <router-link :to="menu.link" v-bind:class="[($route.path.includes(menu.link)) ? 'active' : '', 'w-100 d-flex align-items-center']">
                            <img :src="getIcon(menu.icon)" /> 
                            <span class="pl-3">{{ menu.name }}</span>
                            <b-badge pill class="alert mr-1 ml-2">{{menu.alert}}</b-badge>
                        </router-link>
                    </li>
                </ul>
            </div>

        </div>

</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: "sidebar",
    data() {
        return {
            links: [
                { name: "Dashboard", link: "/dashboard", icon: "dashboard"},
                { name: "Guests", link: "/guests", icon: "guests", permission: "view_guest" },
                { name: "Bookings", link: "/bookings", icon: "bookings", permission: "view_booking" },
                { name: "Rooms", link: "/rooms", icon: "rooms", permission: "view_room" },
                { name: "Services", link: "/services", icon: "laundry", permission: "view_service_order" },
                { name: "Restaurant", link: "/restaurant", icon: "restaurant", alert:4, permission: "view_restaurant_order" },
                { name: "Payments", link: "/payments", icon: "payments", permission: "view_payment" },
                { name: "Users", link: "/users", icon: "users", permission: "view_admin_user"},
                { name: "Audit Logs", link: "/logs", icon: "logs", permission: "view_activity_log" },
                { name: "Settings", link: "/settings", icon: "settings", permission: "update_company" },
            ],
            icons: require.context('../assets/icons', false, /\.png$/)
        };
    },
    methods: {
        getIcon(icon) {
            return this.icons('./' + icon + ".png")
        },
        checkPermission(permission) {
            if(permission !== undefined) {
                return this.user.permissions.includes(permission)
            }
            return true;
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        })
    }
    
};
</script>

<style scoped>

    .coy {
        border-top:solid 5px rgba(234,84,85,.12); font-size:15px
    }

    .logo {
        border:solid 2px #fff;
        border-bottom:solid 5px rgba(234,84,85,.12);
        font-size: 7px;
        box-shadow: -10px 0px 8px #555;
    }

    .logo h1 {
        text-shadow: 0px 0px 15px #ccc;
    }

   .app-nav {
       width: 16.67%;
       color: #6e6b7b;
       z-index: 2
   }

   li a {
       color:#23103A !important;
       text-decoration:none;
       font-size:17px;
       font-weight: 500;
       padding: 10px 20px;
   }

   li a:hover {
       background: #f2f8fc;
       border-radius: 3px;
   }

   li a:hover img {
       animation: rotate 0.5s ease;
   }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

   .active, .active:hover {
       border: solid 1px #88cdfc;
       background: #88cdfc;
       font-weight: bold;
       border-radius: 3px;
       box-shadow: 0 1px 20px 1px rgb(64 156 247 / 70%) !important;
   }

   .app-nav .active img {
       opacity: 1;
   }

   .app-nav img {
       width: 25px;
       height:25px;
       opacity: .6;
   }

   .app-nav a {
       color:#000 !important
   }

   .alert {
       background-color: rgba(234,84,85,.12);;
       color: #ea5455!important;
       padding:0px 5px;
       border-radius: 10px;
       font-size: 14px;
       font-weight: 800;
   }

   
</style>