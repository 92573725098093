<template>

    <b-card class="p-2">
        
        <b-table small responsive :fields="fields" :items="orders" class="mt-4 table-fit">

            <template #cell(amount_paid)="data">
                <div>{{ formatMoney(data.item.total_amount) }}</div>
                <small class="d-block text-muted">{{ formatMoney(data.item.payment) }} Paid</small>
            </template>

            <template #cell(time)="data">
                <div>{{ data.item.time_ordered | moment("DD MMM YYYY, h:mm:ss") }}</div>
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell(order)="data">
                <div class="font-weight-bold">#{{data.item.code}}</div>
                <small class="d-block text-muted" v-for="item, n in data.item.items" v-bind:key="n">{{ item.product.name + " x " + item.quantity }}</small>
            </template>

            <template #cell(status)="data">
                <div class="font-weight-bold">
                    <b-badge v-if="data.item.status=='pending'" class="badge-light-primary">Pending</b-badge>
                    <b-badge v-if="data.item.status=='packaging'" class="badge-outline-primary">Packaging</b-badge>
                    <b-badge v-if="data.item.status=='en-route'" class="badge-outline-primary">En-route</b-badge>
                    <b-badge v-if="data.item.status=='delivered'" class="badge-light-success">Delivered</b-badge>
                </div>
                <small class="text-muted">{{ data.item.checkin | moment("DD MMM YYYY") }} - {{ data.item.checkout | moment("DD MMM YYYY") }}</small>
            </template>

        </b-table>

    </b-card>
            
</template>

<script>

    import {formatMoney, formatNumber} from '../../../utils/helpers.js';
    import { GuestService } from "@/services";

    export default({
        name:'products-table',
        props:['id', 'type'],
        methods: {
            formatMoney,
            formatNumber,
            async getOrders() {
                let {data} = await GuestService.getOrders(this.id, this.type);
                this.orders = data.orders;
            }
        },
        data: () => {
            return {
                fields: ['order', 'time', 'amount_paid', 'status'],
                orders:[]
            }
        },
        mounted() {
            this.getOrders();
        }
    })
</script>

<style scoped>
    


</style>