import Vue from "vue";
import { resolveRequestError } from "../utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class AuthService {

    /**
     * Attempt to register
     * @param {Object} userdata
     *
     * @async
     * @throws will throw error if it is form validation error
     * @return {Object} returns user object
     */
    static async register(userdata) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.REGISTER, userdata);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    /**
     *  Attempt to login
     * @param {Object} data
     * @param {String} data.email
     * @param {String} data.password
     *
     * @async
     * @throws will throw error if it is form validation error
     * @return {Object} returns user object
     */
    static async login({ email, password }) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.LOGIN, { email, password });
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }


    /**
     *  Fetch profile
     *
     * @async
     * @return {Object} 
     */
    static async getProfile() {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.PROFILE);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    /**
     *  Change password
     * @param {Object} data
     * @param {String} data.oldpassword
     * @param {String} data.newpassword
     * @param {String} data.newpasswordconfirm
     *
     * @async
     * @return {Object} returns message
     * @throws form validation errors
     */
    static async changePassword({
        oldpassword,
        newpassword,
        newpasswordconfirm
    }) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CHANGE_PASSWORD, {
                oldpassword,
                newpassword,
                newpasswordconfirm
            });
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    /**
     *  Send reset password link to email
     * @param {String} email
     *
     * @async
     * @throws will throw error if it is form validation error
     * @return {Object} returns user object
     */
    static async forgotPassword(email) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.FORGOT_PASSWORD, {
                email
            });
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    /**
     *  Attempt to reset password
     * @param {Object} resetData
     * @param {String} resetData.newpassword
     * @param {String} resetData.newpasswordconfirm
     * @param {String} resetData.token
     *
     * @async
     * @throws will throw error if it is form validation error
     * @return {Object}
     */
    static async resetPassword({ newpassword, newpasswordconfirm, token }) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.RESET_PASSWORD, {
                newpassword,
                newpasswordconfirm,
                token
            });
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }


    /**
     *  Attempt to user's identity via code sent to email
     * @param {String} code
     *
     * @async
     * @return {Object}
     */
    static async sendVerificationCode() {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.SEND_VERIFICATION_CODE);
            return data;
        } catch (err) {
            resolveRequestError(err, true, false);
        }
    }


    /**
     *  Attempt to user's identity via code sent to email
     * @param {String} code
     *
     * @async
     * @return {Object}
     */
    static async verifyCode(code) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.VERIFY_CODE(code));
            return data;
        } catch (err) {
            resolveRequestError(err, true, false);
        }
    }


    static async logout() {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.LOGOUT);
            return data;
        } catch (err) {
            console.log(err.message);
        }
    }


}