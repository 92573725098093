<template>
  <div>
    <edit-room :room="selected_room" @roomSaved="fetchRooms" />
    
    <b-card class="p-2">
      <b-row>
          <b-col>
              <h5 class="font-weight-bold">Room List</h5>
          </b-col>
          <b-col class="text-right">
               <b-col class="text-right">
                  <b-button variant="outline-primary" class="mr-2" to="/room/types">Room Types</b-button>
                  <b-button variant="primary" v-if="user.permissions.includes('create_room')" @click="selected_room = null" v-b-toggle.edit-room-modal>New Room</b-button>
                </b-col>
          </b-col>
      </b-row>

      <b-table small responsive :fields="fields" :items="items" class="mt-4 table-fit">
        <template #cell(room)="data">
          <div class="font-weight-bold">Room {{ data.item.name }}</div>
        </template>

        <template #cell(room_type)="data">
          <div>{{ data.item.room_type.name + " - " + data.item.room_type.dimension + "sqm" }}</div>
        </template>

        <template #cell(rate)="data">
          <div>{{ formatMoney(data.item.room_type.rate) }}</div>
        </template>

        <template #cell(status)="data">
          <div class="font-weight-bold">
            <b-badge v-if="data.item.status == 'available'" class="badge-light-primary">Available</b-badge>
            <b-badge v-if="data.item.status == 'occupied'" class="badge-light-warning">Occupied</b-badge>
            <b-badge v-if="data.item.status == 'assigned'" class="badge-light-success">Assigned</b-badge>
          </div>
        </template>

        <template #cell(actions)="data" v-if="user.permissions.includes('update_room')">
          <b-button-group size="sm">
            <b-button variant="light" class="border text-primary font-weight-bold" @click="selected_room = data.item" v-b-toggle.edit-room-modal>
              <b-icon icon="pencil-fill" font-scale="0.8" shift-v="6"></b-icon> Edit
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </b-card>

    <b-row v-show="data.total">
      <b-col class="mt-4 font-weight-bold">{{ formatNumber(data.from) + " to " + formatNumber(data.to) + " of " + formatNumber(data.total) }} Rooms</b-col>
      <b-col><b-pagination v-model="data.current_page" :total-rows="data.total" :per-page="data.per_page" align="right" class="mt-3" @input="fetchRooms"></b-pagination></b-col>
    </b-row>
    
  </div>
</template>

<script>
  import {formatNumber, formatMoney} from '../../../utils/helpers.js';
  import EditRoom from "./EditRoom.vue";
  import { RoomService } from "@/services";
  import { mapGetters } from 'vuex';

  export default {
    name: "rooms-table",
    components: {
      EditRoom,
    },
    data: () => {
      return {
        fields: ["room", "room_type", "rate", "status", "actions"],
        data: {},
        items: [],
        request_params: {},
        current_page: 0,
        selected_room: {},
      };
    },
    methods: {
      formatNumber,
      formatMoney,
      filter(filter_params) {
          this.request_params = filter_params;
          this.fetchRooms(1);
      },
      async fetchRooms(page=1) {
        this.current_page = page;
        let { data } = await RoomService.fetchRooms({page:page, ...this.request_params});
        this.data = data;
        this.items = data.data;
      },
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        })
    }
  };
</script>

<style scoped></style>
