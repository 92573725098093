import Vue from "vue";
import { resolveRequestError } from "../utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class OrderService {

    static async fetchServiceOrders(request_params) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.SERVICE_ORDERS, request_params);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createServiceOrder(order) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_SERVICE_ORDER, order);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchRestaurantOrders(request_params) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.RESTAURANT_ORDERS, request_params);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createRestaurantOrder(order) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_RESTAURANT_ORDER, order);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async updateOrder(id, order) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.UPDATE_ORDER(id), order);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getOrder(id) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.GET_ORDER(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async cancelOrder(id) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CANCEL_ORDER(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }
}