<template>
    <div>
        <TopBar title="Admin Users" />

        <search-view 
            :has-filter="true"
            :filter-params="filters"
            :is-loading="false"
            :has-search-field="true"
            @submit="runFilter" />

        <users-table ref="usersTable" />
    </div>
</template>

<script>

    import TopBar from "../../components/TopBar.vue";
    import SearchView from "../../components/SearchView.vue";
    import UsersTable from './components/UsersTable.vue';
    import { UsersService } from '@/services';

    export default {
        name:'users',
        components: {
            TopBar,
            SearchView,
            UsersTable
        },
        data() {
            return {
                filters: [
                    {name: "User Role", field_name: "role_id", options:[]},
                    {name: "User Status", field_name: "is_active", options: [{id:true, name:"active"}, {id:false, name:"inactive"}]},
                    {name: "Email Address", field_name: "email", options: ""},
                ],
            }
        },
        methods: {
            runFilter(filter_params) {
                this.$refs.usersTable.filter(filter_params);
            },
            async fetchRoles(page = 1) {
                this.current_page = page;
                let { data } = await UsersService.fetchRoles();
                this.filters[0].options = data.roles;
            },
        },
        mounted() {
            this.fetchRoles();
        }
    };
    
</script>

<style scoped></style>