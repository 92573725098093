<template>
    <div id="app" class="bg-color">

        <div v-if="appLoading && !loadingError">
          <div class="text-center" style="margin-top:48vh">
            <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
          </div>
        </div>

        <div v-else-if="loadingError" class="loading-app">
          <div style="margin-top:35vh">
            <b-button variant="light" class="w-100">
              Network error, click to retry
            </b-button>
          </div>
        </div>

        <component :is="layout" v-else>
          <router-view />
        </component>

    </div>
</template>

<script>

export default {
    name: "app",
    data() {
        return {
            appLoading: false,
            loadingError:false
        };
    },
    computed: {
      layout() {
        return (this.$route.meta.layout || 'public') + '-layout'
      }
    },
};
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
