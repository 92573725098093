import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, SkeletonPlugin } from 'bootstrap-vue'
import '../assets/styles/bootstrap.css'
import '../assets/styles/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(SkeletonPlugin)

//custom css
import '@/assets/fonts/CircularStd/fonts.css'
import '@/assets/styles/custom.css'