<template>
    <div>
        <TopBar title="Payments" />

        <search-view 
            :has-filter="true"
            :filter-params="filters"
            :is-loading="false"
            :has-search-field="true"
            @submit="runFilter" />

        <payments-table ref="paymentsTable" />
    </div>
</template>

<script>

    import TopBar from "../../components/TopBar.vue";
    import SearchView from "../../components/SearchView.vue";
    import PaymentsTable from "./components/PaymentsTable.vue";

    export default {
        name:'payments',
        data() {
            return {
                filters: [
                    {name: "Payment Channel", field_name: "channel", options: ["cash", "pos", "online", "wallet", "bank_transfer"]},
                    {name: "Payment Status", field_name: "status", options: ["pending", "successful", "failed", "awaiting approval"]},
                    {name: "Payment Purpose", field_name: "type", options: ["bookings", "orders"]},
                ]
            }
        },
        components: {
            TopBar,
            SearchView,
            PaymentsTable,

        },
        methods: {
            runFilter(filter_params) {
                this.$refs.paymentsTable.filter(filter_params);
            }
        }
    };
    
</script>

<style scoped>

    .b-icon {
        background-color:rgba(243, 232, 249, .1);
    }

</style>