<template>
  <div>
    <TopBar title="Room Types" hasBackButton="true" />

    <b-row class="mt-4">
      <b-col v-for="item in items" v-bind:key="item.id" sm="1" md="4" class="mb-5">
        <b-card :title="item.name" class="shadow" :img-src="default_image" width="300" height="150" img-alt="Image" img-top>
          <b-card-text>
            <h6>Dimesion: {{ item.dimesion + "sqm" }}</h6>
            <h6>Rate: {{ formatMoney(item.rate) }}</h6>
            <small v-html="item.description" ></small>
            <hr />
            <b-button variant="light" class="border float-right" :to="'/room/types/' + item.id + '/edit'">
              <b-icon icon="pencil-fill" font-scale="0.8" shift-v="6" class="icon mr-2" />Edit
            </b-button>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { RoomService } from "@/services";
import TopBar from "../../components/TopBar.vue";
import { formatMoney } from "../../utils/helpers";

export default {
  name: "room-types",
  components: {
    TopBar,
  },
  data() {
    return {
      items:[],
      default_image: "https://picsum.photos/300/150/?blur=2"
    };
  },

  mounted() {
    this.fetchRoomTypes();
  },
  methods: {
    formatMoney,
    async fetchRoomTypes(filter_params) {
      let {data} = await RoomService.fetchRoomTypes(filter_params);
      this.items = data.room_types
    },
  },
};
</script>

<style scoped></style>
