import Vue from "vue";
import { resolveRequestError } from "../utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class UsersService {

    static async fetchUsers(request_params) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.USERS, request_params);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchRoles() {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.ROLES);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createUser(user_data) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_USER, user_data);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async updateUser(id, user_data) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.UPDATE_USER(id), user_data);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchUser(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.USER_PROFILE(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async deactivateUser(id) {
        try {
            const { data } = await Vue.http.patch(ENDPOINTS.DEACTIVATE_USER(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async activateUser(id) {
        try {
            const { data } = await Vue.http.patch(ENDPOINTS.ACTIVATE_USER(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchActivityLogs(request_params) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.ACTIVITY_LOGS, request_params);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchModules() {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.MODULES);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }
}