<template>

    <b-form
        @submit.prevent="submit"
        class="text-left"
        autocomplete="off">

        <b-row class="w-40 hor-center shadow-lg login-c border ">
            <b-col class="text-left mb-4" cols="12" sm="12" md="12" lg="12" xl="12">
                <h2 class="text-primary font-weight-bold">Let's<br />get started</h2>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pr-md-2 mb-3">
                <b-form-input
                    class="rounded-lg p-4"
                    type="text"
                    v-model="form.firstname"
                    placeholder="First Name"
                    trim>
                </b-form-input>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pl-md-2 mb-3">
                <b-form-input
                    class="rounded-lg p-4"
                    type="text"
                    v-model="form.lastname"
                    placeholder="Last Name"
                    trim>
                </b-form-input>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <b-form-input
                    class="rounded-lg p-4"
                    type="email"
                    v-model="form.email"
                    placeholder="Email Address"
                    trim>
                </b-form-input>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <b-form-input
                    class="rounded-lg p-4"
                    type="text"
                    v-model="form.business_name"
                    placeholder="Business Name"
                    trim>
                </b-form-input>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <b-form-input
                    class="rounded-lg p-4"
                    type="password"
                    v-model="form.password"
                    autocomplete="new-password"
                    placeholder="Enter Password"
                    trim>
                </b-form-input>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-4">
                <b-form-input
                    class="rounded-lg p-4"
                    type="password"
                    v-model="form.confirmpassword"
                    placeholder="Confirm Password"
                    trim>
                </b-form-input>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <StatefulButton
                    :loading="submitting"
                    loadingText="Processing..."
                    text="Signup"
                    elementClass="w-100 rounded-lg py-2"
                />
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-4">
                <small>Already have an account?</small> <router-link to="/login" class="small text-secondary text-weight-bold">Login</router-link>
            </b-col>
        </b-row>

    </b-form>

</template>

<script>

    import StatefulButton from "../../components/actions/StatefulButton.vue";
    import AuthService from '@/services/auth';
    import { createToast } from '@/utils/helpers';


    export default {
        name: "register",
        components: {
            StatefulButton
        },
        data(){
            return {
                form: {},
                submitting: false,
            }
        },
        methods : {
            async submit() {
                this.submitting = true;

                AuthService.register(this.form).then((response) => {
                    this.submitting = false;
                    createToast(this, response.message, response.status);
                });

            },
        },
       
    }
    
</script>

<style scoped>

   
    
</style>