<template>

  <div>

    <TopBar title="Hotel Settings" />

    <div class="bg-white d-flex justify-content-center py-4 mt-4 shadow-sm rounded">
      <div class="p-3 w-50">
        <h3 class="mb-1 font-weight-bold">Dwella Hotels</h3>
        <div style="font-size: 14px !important;">15 Adeola Odekwu Street, Alausa, Ikeja, Lagos</div>
        <small>
          <b-icon icon="phone" /> +2348094839393
          <b-icon icon="envelope" class="ml-1" /> hello@dwella_hotels.com
        </small>

        <div class="border rounded p-4 my-4">
          <div class="d-flex justify-content-between border-bottom pb-2">
            <h6 class="font-weight-bold">About</h6>
            <b-button variant="light" class="edit-btn p-1"><b-icon icon="pencil" /></b-button>
          </div>
          <div class="text-justify mt-3">We tend towards paying for tools or services as opposed to building them ourselves, if we can put money towards a problem that would otherwise take a lot of our time whilst still creating the user experience we desire then we’ll aim to do so. We’ll try to keep our investments in a few tools/services rather than having many ways to save the same problems</div>
        </div>

        <div class="border rounded p-4 my-4">
          <div class="d-flex justify-content-between border-bottom pb-2">
            <h6 class="font-weight-bold">Hotel Guide</h6>
            <b-button variant="light" class="edit-btn p-1"><b-icon icon="pencil" /></b-button>
          </div>
          <div class="text-justify mt-3">We tend towards paying for tools or services as opposed to building them ourselves, if we can put money towards a problem that would otherwise take a lot of our time whilst still creating the user experience we desire then we’ll aim to do so. We’ll try to keep our investments in a few tools/services rather than having many ways to save the same problems</div>
        </div>

        <div class="border rounded p-4 my-4">
          <div class="d-flex justify-content-between border-bottom pb-2">
            <h6 class="font-weight-bold">Support Channels</h6>
            <b-button variant="light" class="edit-btn p-1"><b-icon icon="pencil" /></b-button>
          </div>
          <b-row class="mt-3">
            <b-col cols="12" md="6" sm="12" class="social"><img :src="icons.facebook" /> <a href="">facebook.com/dwella</a></b-col>
            <b-col cols="12" md="6" sm="12" class="social"><img :src="icons.twitter" /> <a href="">twitter.com/dwella</a></b-col>
            <b-col cols="12" md="6" sm="12" class="social"><img :src="icons.instagram" /> <a href="">instagram.com/dwella</a></b-col>
            <b-col cols="12" md="6" sm="12" class="social"><img :src="icons.whatsapp" /> 08038130090</b-col>
            <b-col cols="12" md="6" sm="12" class="social"><img :src="icons.call" /> 09034838383</b-col>
            <b-col cols="12" md="6" sm="12" class="social"><img :src="icons.email" /> hello@dwella-hotels.com</b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12" md="6" sm="12" class="social"><b>Room Service</b>: 056</b-col>
            <b-col cols="12" md="6" sm="12" class="social"><b>Reception</b>: 057</b-col>
            <b-col cols="12" md="6" sm="12" class="social"><b>Restaurant</b>: 058</b-col>
            <b-col cols="12" md="6" sm="12" class="social"><b>Laundry</b>: 059</b-col>
          </b-row>
        </div>

        <div class="border rounded p-4 my-4">
          <div class="d-flex justify-content-between border-bottom pb-2">
            <h6 class="font-weight-bold">Settlement Account</h6>
            <b-button variant="light" class="edit-btn p-1"><b-icon icon="pencil" /></b-button>
          </div>
          <div class="text-justify mt-3">
            <div>Bank : <b>Eyowo</b></div>
            <div>Account Number : <b>8038130090</b></div>
            <div>Account Name : <b>Dwella Hotels Nig. Ltd</b></div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

import TopBar from "../../components/TopBar.vue";

export default {
  name:'hotel_settings',
  data() {
    return {
      icons: {
        facebook: require("../../assets/icons/facebook.svg"),
        instagram: require("../../assets/icons/instagram.svg"),
        twitter: require("../../assets/icons/twitter.svg"),
        whatsapp: require("../../assets/icons/whatsapp.svg"),
        email: require("../../assets/icons/email.svg"),
        call: require("../../assets/icons/call.svg")
      }
    }
  },
  components: {
    TopBar
  }
};

</script>

<style scoped>

.edit-btn {
  margin-top: -10px !important;
}

.social {
  margin:5px 0;
}

.social img {
  width: 20px;
  height:20px
}


</style>