<template>

    <b-form
        @submit="submit"
        class="text-left"
        autocomplete="off">

        <b-row class="mt-5">

            <b-col cols="12" sm="12" md="12" lg="4" xl="4">

            </b-col>

            <b-col cols="12" sm="12" md="12" lg="8" xl="8" >

                <b-row>
                    <b-col class="text-left mb-4" cols="12" sm="12" md="12" lg="12" xl="12">
                        <h2 class="text-primary font-weight-bold">You're almost there!<br />Create your business profile</h2>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                        <b-row>
                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                                <b-form-input
                                    class="rounded-lg p-4"
                                    type="text"
                                    v-model="form.businessname"
                                    placeholder="Business Name"
                                    trim>
                                </b-form-input>
                            </b-col>
                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                                <b-form-input
                                    class="rounded-lg p-4"
                                    type="text"
                                    v-model="form.email"
                                    placeholder="Business Email Address"
                                    trim>
                                </b-form-input>
                            </b-col>
                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                                <b-form-input
                                    class="rounded-lg p-4"
                                    type="email"
                                    v-model="form.phonenumber"
                                    placeholder="Phone Number"
                                    trim>
                                </b-form-input>
                            </b-col>
                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                                <b-form-input
                                    class="rounded-lg p-4"
                                    type="text"
                                    v-model="form.address"
                                    placeholder="Address"
                                    trim>
                                </b-form-input>
                            </b-col>
                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                                <b-form-select
                                    class="rounded-lg p-4" 
                                    v-model="form.industry"
                                    :options="industries">
                                </b-form-select>
                            </b-col>
                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-4">
                                <b-form-textarea
                                    class="rounded-lg p-4" 
                                    v-model="form.description"
                                    placeholder="Describe your business"
                                    rows="3"
                                    max-rows="6">
                                </b-form-textarea>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                        <b-row>
                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-1 text-weight-bold"><small>COLOR SCHEME</small></b-col>
                            <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pr-2 mb-3">
                                <b-form-input
                                    class="rounded-lg p-4"
                                    type="text"
                                    v-model="form.primarycolor"
                                    placeholder="Primary Color"
                                    trim>
                                </b-form-input>
                            </b-col>
                            <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pl-2 mb-3">
                                <b-form-input
                                    class="rounded-lg p-4"
                                    type="text"
                                    v-model="form.secondarycolor"
                                    placeholder="Secondary Color"
                                    trim>
                                </b-form-input>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                                <b-form-file
                                    class="rounded-lg"
                                    v-model="form.logofile"
                                    :state="Boolean(logofile)"
                                    placeholder="Upload Logo"
                                    drop-placeholder="Drop file here..."
                                    accept=".jpg, .jpeg, .png, .gif"
                                    size="md">
                                </b-form-file>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                                <b-form-input
                                    class="rounded-lg p-4"
                                    type="text"
                                    v-model="form.website"
                                    placeholder="Link to Website"
                                    trim>
                                </b-form-input>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-1 text-weight-bold"><small>IDENTITY VERIFICATION</small></b-col>
                            <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pr-2 mb-3">
                                <b-form-input
                                    class="rounded-lg p-4"
                                    type="text"
                                    v-model="form.primarycolor"
                                    placeholder="Primary Color"
                                    trim>
                                </b-form-input>
                            </b-col>
                            <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pl-2 mb-3">
                                <b-form-input
                                    class="rounded-lg p-4"
                                    type="text"
                                    v-model="form.secondarycolor"
                                    placeholder="Secondary Color"
                                    trim>
                                </b-form-input>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-5">
                                <StatefulButton
                                    :loading="submitting"
                                    loadingText="Processing..."
                                    text="Signup"
                                    elementClass="w-100"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    
                </b-row>
            </b-col>
        </b-row>

    </b-form>

</template>

<script>

    import StatefulButton from "../../components/actions/StatefulButton.vue";

    export default {
        name: "setup",
        components: {
            StatefulButton
        },
        data(){

            return {
                form: {industry:''},
                submitting: false,
                industries: [
                    { value: '', text: 'Select Industry' },
                ]
            }

        },
    }

    
</script>

<style scoped>

   
    
</style>