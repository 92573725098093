<template>
  <div>
    <TopBar title="Guests" />

    <search-view
      :has-filter="true"
      :filter-params="filters"
      :has-search-field="true"
      @submit="runFilter"
    />

    <div class="mt-4 w-100">
      <customers-table ref="guestTable" />
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import SearchView from "@/components/SearchView.vue";
import CustomersTable from "./components/CustomersTable.vue";

export default {
  name: "guests",
  data() {
    return {
      filters: [
        { name: "Email Address", field_name: "email", options: "" },
        { name: "Phone Number", field_name: "phone_number", options: "" },
        { name: "Gender", field_name: "gender", options: ["male", "female"] },
      ]
    };
  },
  components: {
    TopBar,
    SearchView,
    CustomersTable,
  },
  methods: {
    runFilter(filter_params) {
      this.$refs.guestTable.filter(filter_params);
    },
  }
};
</script>

<style scoped></style>
