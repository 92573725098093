<template>
    <div>
        <b-card class="p-2">
            <b-row>
                <b-col>
                    <h5 class="font-weight-bold">Guest List</h5>
                </b-col>
                <b-col class="text-right" v-if="user.permissions.includes('create_booking')">
                    <b-button variant="primary" v-b-toggle.sidebar-edit-guest @click.prevent>
                        <b-icon icon="person-plus" aria-hidden="true" class="icon"></b-icon>
                        New Guest
                    </b-button>
                </b-col>
            </b-row>

            <b-table small responsive :fields="fields" :items="items" class="mt-4 table-fit">
                <!-- customer name -->
                <template #cell(guest)="data">
                    <router-link :to="`/guests/profile/${data.item.id}`">
                        <strong>{{ data.item.first_name + " " + data.item.last_name }}</strong>
                    </router-link>
                </template>

                <!-- product name -->
                <template #cell(date_registered)="data">
                    {{ data.item.date_registered | moment("DD MMM YYYY") }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell(bookings)="data">
                    {{ formatNumber(data.item.bookings.length) }}
                </template>

                <template #cell(status)="data">
                    <span class="font-weight-bold">
                        <span v-if="!data.item.status" class="text-warning">pending</span>
                        <span v-if="data.item.status" class="text-success"><b-icon icon="check2-circle" class="icon" /> active</span>
                    </span>
                </template>
            </b-table>
        </b-card>

        <b-row v-show="data.total">
            <b-col class="mt-4 font-weight-bold">{{ formatNumber(data.from) + " to " + formatNumber(data.to) + " of " + formatNumber(data.total) }} Guests</b-col>
            <b-col><b-pagination v-model="data.current_page" :total-rows="data.total" :per-page="data.per_page" align="right" class="mt-3" @input="getGuests"></b-pagination></b-col>
        </b-row>
            
        <edit-profile @newCustomerCreated="getGuests()" />
    </div>
</template>

<script>

    import {formatMoney, formatNumber} from '../../../utils/helpers.js';
    import EditProfile from "./EditProfile.vue";
    import { GuestService } from "@/services";
    import { mapGetters } from 'vuex';

    export default({
        name:'customers-table',
        props: ['filter_form'],
        components: {
            EditProfile
        },
        methods: {
            formatMoney,
            formatNumber,
            filter(filter_params) {
                this.request_params = filter_params;
                this.getGuests(1);
            },
            async getGuests(page = 1) {
                this.current_page = page;
                let { data } = await GuestService.getGuests({page:page, ...this.request_params});
                this.data = data;
                this.items = data.data;
            }
        },
        data: () => {
            return {
                data: {},
                items: [],
                request_params: {},
                current_page: 0,
                fields: ['guest', 'email', 'phone_number', 'date_registered', 'status']
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/user",
            })
        }
    })
</script>

<style scoped></style>