<template>
    <div>
        <TopBar :title="$route.params.product_type + ' Products'" :hasBackButton="true" />

        <search-view 
            :has-filter="true"
            :filter-params="filters"
            :is-loading="false"
            :has-search-field="true"
            @submit="runFilter" />

        <product-table ref="productTable" :type="$route.params.product_type" />
    </div>
</template>

<script>

    import TopBar from "../../components/TopBar.vue";
    import SearchView from "../../components/SearchView.vue";
    import ProductTable from "./components/ProductTable";

    export default {
        name:'products',
        data() {
            return {
                filters: [
                    { name: "Category", field_name: "product_category_id", options: [] },
                ],
            }
        },
        components: {
            TopBar,
            SearchView,
            ProductTable
        },
        methods: {
            runFilter(filter_params) {
                this.$refs.productTable.filter(filter_params);
            }
        }
    };
    
</script>

<style scoped></style>