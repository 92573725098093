<template>
    
    <b-form
        @submit="submit"
        class="text-left"
        autocomplete="off">

        <b-row class="w-40 hor-center login-c shadow-lg p-5">
            <b-col class="text-left mb-4 mt-3" cols="12" sm="12" md="12" lg="12" xl="12">
                <h2 class="text-primary font-weight-bold">Reset<br />password</h2>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <b-form-input
                    id="password"
                    class="rounded-lg p-4"
                    v-model="form.newpassword"
                    type="password"
                    placeholder="Enter new password"
                    trim>
                </b-form-input>
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <b-form-input
                    id="passwordconfirm"
                    class="rounded-lg p-4"
                    v-model="form.newpasswordconfirm"
                    type="password"
                    placeholder="Confirm password"
                    trim>
                </b-form-input>
            </b-col>
            
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <StatefulButton
                    :loading="submitting"
                    loadingText="Processing..."
                    text="Proceed"
                    elementClass="w-100 rounded-lg py-2"
                />
            </b-col>

            <b-col cols="8" class="mb-3">
                <small>Don't have an account? <router-link to="/register" class="text-secondary text-weight-bold">Create one</router-link></small>
            </b-col>
            <b-col cols="4" class="mb-3 text-right">
                <small><router-link to="/login" class="text-secondary text-weight-bold">Back to login</router-link></small>
            </b-col>
        </b-row>

    </b-form>
        
        
</template>


<script>


    import StatefulButton from "../../components/actions/StatefulButton.vue";
    //import { isUserLoggedIn } from "../../utils/auth";

    import { AuthService } from "../../services";

    import { createToast } from "../../utils/helpers";

    export default {
        name: "Reset-Password",
        components: {
            StatefulButton
        },
        data(){
            return {

                form: {
                    password:'',
                    passwordconfirm:'',
                    token:this.$route.params.token
                },
                submitting: false,
                status:""
            }
        },
        methods : {
            createToast,
            async submit(event) {

                event.preventDefault();

                if(this.password!=this.passwordconfirm) {

                    this.status = "Password provided does not match!";

                } else {

                    this.submitting = true;
                    try {

                        let { message, status } = await AuthService.resetPassword(this.form);

                        if(status==1) {

                            this.createToast({message:message});

                            this.$router.replace({ name: "login" });

                        } else {

                            this.status = message;
                        }

                        this.submitting = false;
                        
                    } catch (err) {
                        this.submitting = false;
                    }

                }
                
            },
        },
        mounted() {

            //this.form.token = this.$route.params.action
        },
        /**beforeRouteEnter(to, from, next) {
            if (isUserLoggedIn()) {
                next("/");
            } else {
                next();
            }
        },**/
    }
</script>

<style scoped>

</style>