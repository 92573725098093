<template>
    <div>
        <b-card class="p-2">
            <b-row>
                <b-col>
                    <h5 class="font-weight-bold">Order List</h5>
                </b-col>
                <b-col class="text-right">
                    <b-button variant="outline-primary" :to="'/products/' + product_type" class="mr-2">
                        View Products
                    </b-button>
                    <b-button variant="primary" v-if="hasCreatePermit()">
                        <b-icon icon="person-plus" aria-hidden="true" class="icon"></b-icon>
                        New Order
                    </b-button>
                </b-col>
            </b-row>

            <b-table small responsive :fields="fields" :items="items" class="mt-4 table-fit">
                <!-- customer name -->
                <template #cell(guest)="data">
                    <router-link :to="`/guests/profile/${data.item.guest.id}`">
                        <strong>{{ data.item.guest.first_name + " " + data.item.guest.last_name }}</strong>
                    </router-link>
                </template>

                <template #cell(amount_paid)="data">
                    <div>{{ formatMoney(data.item.total_amount) }}</div>
                    <small class="d-block text-muted font-weight-bold">{{ formatMoney(data.item.payment) }} Paid</small>
                </template>

                <template #cell(order)="data">
                    <div class="font-weight-bold">#{{data.item.code}}</div>
                    <small class="text-muted font-weight-bold">{{ data.item.time_ordered | moment("DD MMM YYYY, h:mm:ss") }}</small>
                </template>

                <template #cell(status)="data">
                    <div class="font-weight-bold">
                        <b-badge v-if="data.item.status=='pending'" class="badge-light-primary">Pending</b-badge>
                        <b-badge v-if="data.item.status=='packaging'" class="badge-light-primary">Packaging</b-badge>
                        <b-badge v-if="data.item.status=='en_route'" class="badge-light-primary">En-route</b-badge>
                        <b-badge v-if="data.item.status=='delivered'" class="badge-light-success">Delivered</b-badge>
                        <b-badge v-if="data.item.status=='cancelled'" class="badge-light-danger">Cancelled</b-badge>
                    </div>
                </template>

                <template #cell(action)="data">
                    <b-button-group size="sm">
                        <b-button variant="light" class="border text-primary font-weight-bold" v-if="data.item.status=='pending' && hasUpdatePermit()" v-b-toggle.sidebar-edit-booking @click="selected_booking = data.item">
                            <b-icon icon="pencil-fill" font-scale="0.8" shift-v="6" class="icon mr-2" />Update
                        </b-button>
                        <b-button variant="light" class="border text-success font-weight-bold" v-if="data.item.amount_paid<data.item.total_amount && data.item.status !== 'cancelled' && user.permissions.includes('create_payment')" @click="openPaymentForm(data.item)">
                            <b-icon icon="credit-card2-back-fill" class="icon mr-2" font-scale="0.8" shift-v="6" />Pay
                        </b-button>
                        <b-button variant="light" class="border text-danger font-weight-bold" v-if="data.item.status=='pending' && data.item.amount_paid==0 && hasCancelPermit()" @click="cancel(data.item)">
                            <b-icon icon="x-square-fill" class="icon mr-2" font-scale="0.8" shift-v="6" />Cancel
                        </b-button>
                    </b-button-group>
                </template>

            </b-table>
        </b-card>

        <b-row v-show="data.total">
            <b-col class="mt-4 font-weight-bold">{{ formatNumber(data.from) + " to " + formatNumber(data.to) + " of " + formatNumber(data.total) }} Orders</b-col>
            <b-col><b-pagination v-model="data.current_page" :total-rows="data.total" :per-page="data.per_page" align="right" class="mt-3" @input="getOrders"></b-pagination></b-col>
        </b-row>
    </div>
</template>

<script>

    import {formatMoney, formatNumber} from '../../../utils/helpers.js';
    import { OrderService } from "@/services";
    import { mapGetters } from 'vuex';

    export default({
        name:'customers-table',
        props: ['product_type'],
        components: {},
        methods: {
            formatMoney,
            formatNumber,
            filter(filter_params) {
                this.request_params = filter_params;
                this.getOrders();
            },
            async getOrders(page = 1) {
                this.current_page = page;
                let { data } = this.product_type === 'service' ? 
                                await OrderService.fetchServiceOrders({page:page, ...this.request_params}) :
                                await OrderService.fetchRestaurantOrders({page:page, ...this.request_params});

                this.data = data;
                this.items = data.data;
            },
            hasCreatePermit() {
                if(this.productType === 'restaurant') {
                    return this.user.permissions.includes('create_restaurant_order');
                }
                return this.user.permissions.includes('create_service_order');
            },
            hasCancelPermit() {
                if(this.productType === 'restaurant') {
                    return this.user.permissions.includes('cancel_restaurant_order');
                }
                return this.user.permissions.includes('cancel_service_order');
            },
            hasUpdatePermit() {
                if(this.productType === 'restaurant') {
                    return this.user.permissions.includes('update_restaurant_order');
                }
                return this.user.permissions.includes('update_service_order');
            },
        },
        data: () => {
            return {
                data: {},
                items: [],
                request_params: {},
                current_page: 0,
                fields: ['guest', 'order', 'amount_paid', 'status', 'action']
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/user",
            })
        }
    })
</script>

<style scoped></style>