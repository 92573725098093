<template>
  <div>
    <TopBar title="Rooms" />

    <search-view
      :has-filter="true"
      :filter-params="filters"
      :is-loading="loading"
      :has-search-field="true"
      @submit="runFilter"
    />

    <div class="mt-2">
      <rooms-table ref="roomsTable" />
    </div>
  </div>
</template>

<script>
import { RoomService } from "@/services";
import TopBar from "../../components/TopBar.vue";
import SearchView from "../../components/SearchView.vue";
import RoomsTable from "./components/RoomsTable.vue";

export default {
  name: "rooms",
  components: {
    TopBar,
    SearchView,
    RoomsTable,
  },
  data() {
    return {
      loading: false,
      filters: [
        {name: "Room Type", field_name: "room_type_id", options: this.room_types},
        {name: "Room Status", field_name: "status", options: ["occupied", "available", "unavailable", "assigned"]},
      ],
    };
  },
  mounted() {
    this.fetchRoomTypes();
  },
  methods: {
    runFilter(filter_params) {
      this.$refs.roomsTable.filter(filter_params);
    },
    async fetchRoomTypes() {
      let {data} = await RoomService.fetchRoomTypes();
      this.filters[0].options = data.room_types;
    },
  },
};
</script>

<style scoped></style>
