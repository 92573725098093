<template>
    <div>
        <b-card class="p-2">
            <b-row>
                <b-col>
                    <h5 class="font-weight-bold">Payments</h5>
                </b-col>
            </b-row>

            <b-table small responsive :fields="fields" :items="items" class="mt-4 table-fit">

                <template #cell(guest)="data">
                    <router-link :to="`/guests/profile/${data.item.guest.id}`">
                        <strong>{{ data.item.guest.first_name + " " + data.item.guest.last_name }}</strong>
                    </router-link>
                    <small class="d-block text-muted mt-1 font-weight-bold">
                        <span class="text-capitalize">{{ data.item.entity }}</span>
                        {{"#" + data.item.entity_details.code  }}
                    </small>
                </template>

                <template #cell(amount)="data">  
                    {{ formatMoney(data.item.amount) }}
                    <small class="d-block text-muted mt-1 font-weight-bold">{{ data.item.date | moment("DD MMM YYYY, h:mm:s") }}</small>
                </template>

                <template #cell(channel)="data">
                    <div>
                        <b-badge v-if="data.item.channel=='cash'" class="badge-light-primary">Cash</b-badge>
                        <b-badge v-if="data.item.channel=='pos'" class="badge-light-danger">POS</b-badge>
                        <b-badge v-if="data.item.channel=='wallet'" class="badge-light-primary">Wallet</b-badge>
                        <b-badge v-if="data.item.channel=='online'" class="badge-light-success">Online</b-badge>
                        <b-badge v-if="data.item.channel=='bank_transfer'" class="badge-light-warning">Bank Transfer</b-badge>
                        <small class="d-block text-muted mt-1 font-weight-bold" v-if="data.item.reference">{{ "Ref: " + data.item.reference }}</small>
                    </div>
                </template>

                <template #cell(status)="data">
                    <div class="font-weight-bold">
                        <b-badge v-if="data.item.status=='pending'" class="badge-light-warning">Pending</b-badge>
                        <b-badge v-if="data.item.status=='failed'" class="badge-light-danger">Failed</b-badge>
                        <b-badge v-if="data.item.status=='verified'" class="badge-light-primary">Verified</b-badge>
                        <b-badge v-if="data.item.status=='successful'" class="badge-light-success">Successful</b-badge>
                        <b-badge v-if="data.item.status=='rejected'" class="badge-light-danger">Rejected</b-badge>
                    </div>
                </template>

                <template #cell(action)="data">
                    <b-button-group size="sm">
                        <b-button variant="light" class="border text-primary font-weight-bold" @click="verify(data.item)" v-if="data.item.status === 'pending' && data.item.channel === 'bank_transfer' && user.permissions.includes('verify_payment')">
                            <b-icon icon="pencil-fill" font-scale="0.8" shift-v="6" class="icon mr-2" />Verify
                        </b-button>
                        <b-button variant="light" class="border text-success font-weight-bold" @click="approve(data.item)" v-if="data.item.status == 'verified' && data.item.channel === 'bank_transfer' && user.permissions.includes('approve_payment')">
                            <b-icon icon="pencil-fill" font-scale="0.8" shift-v="6" class="icon mr-2" />Approve
                        </b-button>
                        <b-button variant="light" class="border text-danger font-weight-bold" @click="disapprove(data.item)" v-if="(data.item.status == 'verified' || data.item.status == 'pending') && data.item.channel === 'bank_transfer' && user.permissions.includes('reject_payment')">
                            <b-icon icon="x-square-fill" font-scale="0.8" shift-v="6" class="icon mr-2" />Disapprove
                        </b-button>
                    </b-button-group>
                </template>

            </b-table>

        </b-card>

        <b-row v-show="data.total">
            <b-col class="mt-4 font-weight-bold">{{ formatNumber(data.from) + " to " + formatNumber(data.to) + " of " + formatNumber(data.total) }} Orders</b-col>
            <b-col><b-pagination v-model="data.current_page" :total-rows="data.total" :per-page="data.per_page" align="right" class="mt-3" @input="fetchPayments"></b-pagination></b-col>
        </b-row>
    </div>
            
</template>

<script>

    import {formatMoney, formatNumber} from '../../../utils/helpers.js';
    import { PaymentService } from '@/services';
    import { createToast } from '@/utils/helpers';
    import { mapGetters } from 'vuex';

    export default({
        name:'products-table',
        methods: {
            formatMoney,
            formatNumber,
            filter(filter_params) {
                this.request_params = filter_params;
                this.fetchPayments();
            },
            async fetchPayments(page = 1) {
                this.current_page = page;
                let { data } = await PaymentService.fetchPayments({page:page, ...this.request_params});
                this.data = data;
                this.items = data.data;
            },
            verify(payment) {
                this.$bvModal.msgBoxConfirm('Please confirm that you really want to verify this payment', {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    cancelVariant: 'light',
                    okTitle: 'Verify',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if(value) {
                        PaymentService.verifyPayment(payment.id).then(data => {
                            createToast(this, data.message, data.success);
                            this.fetchPayments();
                        })
                    }
                })
            },
            disapprove(payment) {
                this.$bvModal.msgBoxConfirm('Please confirm that you really want to disapprove this payment', {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    cancelVariant: 'light',
                    okTitle: 'Disapprove',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if(value) {
                        PaymentService.disapprovePayment(payment.id).then(data => {
                            createToast(this, data.message, data.success);
                            this.fetchPayments();
                        })
                    }
                })
            },
            approve(payment) {
                this.$bvModal.msgBoxConfirm('Please confirm that you really want to approve this payment', {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    cancelVariant: 'light',
                    okTitle: 'Approve',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if(value) {
                        PaymentService.approvePayment(payment.id).then(data => {
                            createToast(this, data.message, data.success);
                            this.fetchPayments();
                        })
                    }
                })
            }
        },
        data: () => {
            return {
                data: {},
                items: [],
                request_params: {},
                current_page: 0,
                fields: ['guest', 'amount', 'channel', 'status',  'action'],
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/user",
            })
        }
    })
</script>

<style scoped>
    


</style>