<template>

    <div>

        <TopBar title="Dashboard" />

        <search-view 
          :filter-params="search_filters"
          :is-loading="searching" 
          v-on:submit="run_filter" />

        <!--<b-row class="mt-4">
            <b-col cols="12" sm="12" md="3">
                <data-card title="TOTAL BOOKINGS" fontsize="md" type="currency" value="65705600" color="green" icon="reception4" to="bookings" />
            </b-col>

            <b-col cols="12" sm="12" md="3">
                <data-card title="RESTAURANT SALES" fontsize="md" type="currency" value="1278600" color="blue" icon="basket2" to="restaurant" />
            </b-col>

            <b-col cols="12" sm="12" md="3">
                <data-card title="ADD-ON SERVICES" fontsize="md" type="currency" value="12478490" color="orange" icon="cart-check" to="restaurant/add-on-services" />
            </b-col>

            <b-col cols="12" sm="12" md="3">
              <data-card title="LAUNDRY SERVICES" value="825000" type="currency" fontsize="md" color="red" icon="droplet-half" to="laundry" />
            </b-col>

        </b-row>

        <b-row class="mt-2">
            <b-col cols="12" sm="12" md="5" class="mb-4 mb-md-0">
                <sales-chart />
            </b-col>
            <b-col cols="12" sm="12" md="4" class="mb-4 mb-md-0 bestsellers">
                <room-performance />
            </b-col>
            <b-col cols="12" sm="12" md="3" class="mb-4 mb-md-0">
                <data-card title="ROOMS BOOKED" value="82" to="bookings/rooms?status=booked" />
                <data-card title="AVAILABLE ROOMS" value="72" to="bookings/rooms?status=available" />
                <data-card title="TOTAL GUESTS" value="10480" to="guests"  />
            </b-col>
        </b-row>

      <b-row class="mt-2">
        <b-col cols="12" sm="12" md="6" class="mb-4 mb-md-0">
          <b-card class="lg-card rounded-lg p-2">
            <div class="card-title font-weight-bold">LATEST BOOKINGS</div>
          </b-card>
        </b-col>

        <b-col cols="12" sm="12" md="6" class="mb-4 mb-md-0">
          <b-card class="lg-card rounded-lg p-2">
            <div class="card-title font-weight-bold" style="border-bottom: solid 1px #eee; padding-bottom: 40px">
              <div style="float:left">LATEST REVIEWS</div>
              <div style="color:#edb311; font-size: 28px; float: right; margin-top:-10px">
                <b-icon icon="star-fill" aria-hidden="true" class="icon"></b-icon>
                <b-icon icon="star-fill" aria-hidden="true" class="icon"></b-icon>
                <b-icon icon="star-fill" aria-hidden="true" class="icon"></b-icon>
                <b-icon icon="star-half" aria-hidden="true" class="icon"></b-icon>
                <b-icon icon="star" aria-hidden="true" class="icon"></b-icon>
              </div>
              <div class="clear:both"></div>
            </div>

          </b-card>
        </b-col>

      </b-row>-->

    </div>
    
</template>

<script>

    import TopBar from "../../components/TopBar.vue";
    import SearchView from "../../components/SearchView.vue";
    

    export default {
        name:'dashboard',
        components: {
            TopBar,
            SearchView,
        },
        methods: {
          run_filter() {
            this.searching = true;
          }
        },
        data() {
            return {
                form: {},
                search_filters: [
                  {name: "Room Type", field_name: "room_type_id", options:[
                    {name:"Executive Suite", id:1},
                    {name:"Kings Suite", id:2},
                    {name:"Queens Palace", id:3},
                  ]},
                  {name: "Gender", field_name: "gender", options: ["male", "female"]},
                  {name: "Age Range", field_name: "age_range", options: ["20-35", "36-45", "46-55", "56-70"]},
                  {name: "Payment Channel", field_name: "payment_method", options: ["online", "wallet", "bank transfer"]},
                ],
                searching: false
            }
        },
    };
    
</script>

<style scoped>

        

</style>