<template>
    <b-sidebar id="edit-product-modal" ref="edit_product_modal" backdrop shadow right body-class="sidebar" :title="!product ? 'Create Product' : 'Edit Product'" :ok="on_submit">
        <div class="p-4">
            <b-form-group label="Product Name" label-for="name">
                <b-form-input id="name" v-model="form.name"  />
                <small class="text-danger" v-if="errors.name">{{ errors.name[0] }}</small>
            </b-form-group>
          
            <b-form-group label="Product Category" label-for="product_category" class="mb-4">
                <div class="d-flex">
                    <div class="w-75">
                        <b-form-select id="product_category" v-model="form.product_category_id" value-field="id" text-field="name" :options="product_categories"></b-form-select>
                        <small class="text-danger" v-if="errors.product_category_id">{{ errors.product_category_id[0] }}</small>
                    </div>
                    <b-button variant="dark" class="ml-2 w-25" to="/product/categories/create">New</b-button>
                </div>
            </b-form-group>

            <b-form-group label="Description" label-for="description">
                <b-form-textarea id="description" v-model="form.description"  />
                <small class="text-danger" v-if="errors.description">{{ errors.description[0] }}</small>
            </b-form-group>

            <b-form-group label="Price" label-for="price">
                <b-form-input id="price" v-model="form.price"  />
                <small class="text-danger" v-if="errors.price">{{ errors.price[0] }}</small>
            </b-form-group>

            <b-form-group label="Images" label-for="images">
                <b-form-file id="images" v-model="form.images" multiple="true" />
                <small class="text-danger" v-if="errors.images">{{ errors.images[0] }}</small>
            </b-form-group>

            <hr />

            <b-button @click="on_submit" variant="primary" class="w-100">Submit</b-button>
        </div>
    </b-sidebar>
</template>

<script>
import { ProductService } from "@/services";
import { createToast } from '@/utils/helpers';

export default({
    name:'edit-product',
    props: ['product', 'type'],
    data() {
        return {
            title:"Product",
            product_categories: [],
            form: {
                name: '',
                product_category_id: '',
                description: '',
                price: 0,
                images: [],
            },
            loading: false,
            errors: [],
        }
    },
    methods: {
        async on_submit(e) {
            e.preventDefault();
            try {
                this.is_loading = true;

                let data;

                if(this.form.id === undefined) {
                    data = this.type === 'restaurant' ?
                        await ProductService.createRestaurantProduct(this.form) :
                        await ProductService.createServiceProduct(this.form) 
                } else {
                    data = this.type === 'restaurant' ?
                        await ProductService.updateRestaurantProduct(this.form.id, this.form) :
                        await ProductService.updateServiceProduct(this.form.id, this.form) 
                }

                this.is_loading = false;

                createToast(this, data.message, data.success);

                this.$refs.edit_product_modal.hide();
                this.reset();
                
                this.$emit('productSaved');
            } catch (err) {
                this.is_loading = false;
                this.errors = err;
            }
        },
        async fetchProductCategories() {
            let {data} = this.type === 'restaurant' ?
                        await ProductService.fetchRestaurantProductCategories() :
                        await ProductService.fetchServiceProductCategories();

            this.product_categories = data.product_categories;
        },
        reset() {
            this.form = {
                name: '',
                product_category_id: '',
                description: '',
                price: 0,
                images: [],
            }
        }
    },
    mounted: function() {
        this.fetchProductCategories();
    },
    watch: {
        product: {
            handler(val){
                if(val != null && val != undefined) {
                    this.form = val;
                }
            },
            deep: true
        }
    }
})
</script>

<style scoped></style>
