<template>

    <div class="search_view" v-if="open">
        <div class="filter">
            <b-form @submit.prevent="on_submit">
                <b-row>
                    <b-col class="pr-1" sm="12" md="3">
                        <div class="d-flex flex-row">

                            <b-dropdown 
                                ref="filter_dropdown" 
                                v-on-clickaway="hide_dropdown" 
                                @hide="handle_hide_event" 
                                class="dropdown p-0 rounded-sm mr-2 bg-white" 
                                toggle-class="text-decoration-none" 
                                variant="none" 
                                no-caret
                                v-if="hasFilter && filterParams != null">

                                <template #button-content>
                                    <b-icon icon="filter" class="icon" font-scale="1.5" />
                                </template>

                                <div v-for="filter in filterParams" v-bind:key="filter.field_name">
                                    
                                    <b-dropdown-item 
                                        @click="select_filter(filter)" 
                                        v-show="filter_layer==1" 
                                        class="text-dark">
                                        {{ filter.name }}
                                    </b-dropdown-item>
                                    
                                    <b-dropdown-form v-if="filter.name==filter_selected" v-show="filter_layer==2" class="pt-4 pb-4">

                                        <div class="mb-3 font-weight-bold d-block font-12">{{filter.name}}</div>
                                       
                                        <b-form-input 
                                            v-if="filter.options==''" 
                                            class="mb-2" 
                                            v-model="values[filter.field_name]" 
                                            style="width:240px; border:solid 1px #CCC !important; border-radius:5px !important" 
                                            :placeholder="`Enter ${filter.name}`">
                                        </b-form-input>

                                       <b-form-radio-group  class="mt-2" v-else-if="filter.options[0] instanceof Object ">
                                            <b-radio 
                                                v-for="option in filter.options" 
                                                v-bind:key="option.id" 
                                                v-model="values[filter.field_name]" 
                                                :value="option.id" 
                                                class="mb-3 text-capitalize">
                                                {{option.name}}
                                            </b-radio>
                                        </b-form-radio-group>

                                        <b-form-radio-group class="mt-2" v-else>
                                            <b-radio 
                                                v-for="option in filter.options" 
                                                v-bind:key="option" 
                                                v-model="values[filter.field_name]" 
                                                :value="option" 
                                                class="mb-3 text-capitalize">
                                                {{option.replace("_", " ")}}
                                            </b-radio>
                                        </b-form-radio-group>

                                        <div class="err-msg" v-if="showErrMsg">Input cannot be empty!</div>

                                        <div class="mt-3 d-flex justify-content-between">

                                            <b-button 
                                                @click="layer_back" 
                                                variant="light" 
                                                size="sm">
                                                <b-icon class="icon" icon="arrow-left-short" /> Back
                                            </b-button>

                                            <b-button 
                                                variant="primary" 
                                                size="sm" 
                                                @click="add_filter(filter.field_name, values[filter.field_name])">
                                                Apply
                                            </b-button>

                                        </div>

                                    </b-dropdown-form>

                                </div>

                            </b-dropdown>

                            <b-form-input 
                                v-model="search_param" 
                                type="text" 
                                placeholder="Search..." 
                                class="p-4 rounded-sm" 
                                v-if="hasSearchField">
                            </b-form-input>

                        </div>
                    </b-col>

                    <b-col class="pl-1 pr-1" sm="12" md="3">
                        <date-picker 
                            :showHelperButtons="true"
                            :dateInput="dateInput"
                            @date-applied="setDate"
                            @on-reset="onDateReset" />
                    </b-col>

                    <b-col class="pl-1">
                        <stateful-button 
                            variant="dark" 
                            :loading="isLoading" 
                            loadingText="" 
                            text="Filter" 
                            type="button"
                            elementClass="w-25 py-2 px-4 filter-submit"
                            @click="on_submit">
                        </stateful-button>
                    </b-col>

                </b-row>
            </b-form>
        </div>

        <div class="d-flex flex-row mt-2" v-if="Object.keys(form).length > 0">
            <b-button 
                class="bg-white rounded-sm text-dark clear-button shadow-none"
                size="sm" 
                style="font-size:12px" 
                @click="clear_filter">
                Clear all
            </b-button>

            <span 
                class="filter-selection rounded-sm ml-2" 
                style="font-size:12px" 
                v-for="(value, key) in form" 
                v-bind:key="key">
                <span v-html="display_filter(key,value)" class="text-capitalize" />
                <b-icon icon="x-circle-fill" class="icon ml-2" style="cursor:pointer" font-scale="1.2" @click="remove_filter(key)" />
            </span>
        </div>

    </div>

</template>

<script>

    import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
    import { mixin as clickaway } from 'vue-clickaway';
    import Vue from 'vue'
    import StatefulButton from './actions/StatefulButton';

    export default {
        name:'topbar',
        props: {
            filterParams: {
                type: Array,
                default: null
            },
            hasFilter: {
                type: Boolean,
                default: true
            },
            hasSearchField: {
                type: Boolean,
                default: true
            },
            isLoading: {
                type: Boolean,
                default: false
            },
            open: {
                type: Boolean,
                default: true
            }
        },
        mixins: [ clickaway ],
        data() {
            return {
                dateInput: {
                    placeholder:"Filter by Date..."
                },
                search_param:'',
                date_param: {
                    from: "",
                    to: ""
                },
                filter_selected:'',
                form:{},
                filter_layer:1,
                can_hide:false,
                values:{},
                showErrMsg: false
            }
        },
        components: {
            DatePicker,
            StatefulButton
        },
        methods: {
            select_filter(filter) {
                this.filter_selected = filter.name;
                this.filter_layer = 2;
                this.can_hide = false;
                this.$refs.filter_dropdown.hide();
            },
            layer_back() {
                this.filter_layer = 1;
                this.filter_selected = '';
                this.showErrMsg = false;
            },
            handle_hide_event(bvEvent) {
                if (this.can_hide) {
                    this.can_hide = false
                } else {
                    bvEvent.preventDefault()
                }
            },
            hide_dropdown() {
                this.layer_back();
                this.can_hide = true;
                this.$refs.filter_dropdown.hide();
            },
            remove_filter(field_name) {
                Vue.delete(this.form, field_name)
            },
            add_filter(field_name, value) {
                if(value != undefined) {
                    Vue.set(this.form, field_name, value)
                    this.hide_dropdown();
                    this.showErrMsg = false;
                } else {
                    this.showErrMsg = true;
                }
            },
            clear_filter() {
                this.form = {};
            },
            display_filter(field_name, value) {
                const filter = this.filterParams.find(filter => filter.field_name == field_name);
                value = filter.options[0] instanceof Object ? filter.options.find(option => option.id == value).name : value
                return filter.name + ": <b>" + value.replace("_", " ") + "</b>";
            },
            setDate(start_date, end_date) {
                this.date_param.from = this.$moment(start_date).format("YYYY/MM/DD");
                this.date_param.to = this.$moment(end_date).format("YYYY/MM/DD");
            },
            onDateReset() {
                this.date_param.from = "";
                this.date_param.to = "";
            },
            on_submit() {
                let search_params = {...this.form};

                if(this.search_param !== '')
                    search_params.search = this.search_param;

                if(this.date_param.from !== '')
                    search_params.date_range = this.date_param;

                this.$emit('submit', search_params)
            }
        }
        
    };

</script>

<style scoped>

    .search_view {
        margin: 25px 0;
    }

    .filter {
        margin-top: 20px;
    }

    .filter-search {
        padding:10px;
        font-size: 14px;
    }

    .filter input {
       border:solid 1px #CCC !important;
    }

    .filter input:focus {
       border:solid 1px #CCC !important;
    }

    .filter .dropdown {
        height:50px;
        margin-top:0px;
        border:solid 1px #CCC !important;
    }

    .dropdown-menu li {
        min-width: 250px
    }

    small {
        font-size: 14px !important;
    }

    .date-field {
        padding: 30px !important;
    }

    .filter-submit {
        height: 50px;
        font-weight: 700;
    }

    .filter-selection {
        border:solid 1px #ccc;
        padding:5px 10px;
        background: #fff;
    }

    .clear-button {
        border:solid 1px #ccc
    }

    .clear-button:hover {
        background-color: #1a2631 !important;
        color:#efefef !important
    }

    .filter-toggle {
        cursor: pointer;
    }

    .err-msg {
        color:#f51818;
        line-height: 1.2;
        font-size: 11px !important;
    }

</style>