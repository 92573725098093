import VueRouter from "vue-router";
import store from "../store";

import Dashboard from "../views/dashboard";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import ForgotPassword from "../views/auth/ForgotPassword";
import ResetPassword from "../views/auth/ResetPassword";
import BusinessSetup from "../views/business/Setup";
import Profile from "../views/profile";
import Bookings from "../views/bookings";
import Restaurant from "../views/orders/restaurant";
import Products from "../views/orders/products";
import Rooms from "../views/rooms";
import RoomTypes from "../views/rooms/RoomTypes";
import EditRoomType from "../views/rooms/components/EditRoomType";
import Services from "../views/orders/services";
import Guests from "../views/guests";
import GuestProfile from "../views/guests/Profile";
import Users from "../views/users";
import Logs from "../views/logs";
import Payments from "../views/payments";
import Settings from "../views/settings";

const router = new VueRouter({
    mode: "history",
    routes: [{
            path: "/login",
            name: "login",
            component: Login,
            meta: {
                requiresAuth: false,
                title: "Login",
            },
        },
        {
            path: "/register",
            name: "register",
            component: Register,
            meta: {
                requiresAuth: false,
                title: "Register",
            },
        },
        {
            path: "/forgot-password",
            name: "forgotpassword",
            component: ForgotPassword,
            meta: {
                requiresAuth: false,
                title: "Forgot Password",
            },
        },
        {
            path: "/reset-password/:token",
            name: "resetpassword",
            component: ResetPassword,
            meta: {
                requiresAuth: false,
                title: "Reset Password",
            },
        },
        {
            path: "/business/setup",
            name: "setupbusiness",
            component: BusinessSetup,
            meta: {
                requiresAuth: true,
                title: "Business Setup",
                permission: "update_company",
            },
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Dashboard",
            },
        },
        {
            path: "/",
            name: "home",
            component: Dashboard,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Dashboard",
            },
        },
        {
            path: "/guests",
            name: "guests",
            component: Guests,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Guests",
                permission: "view_guest",
            },
        },
        {
            path: "/guests/profile/:id",
            name: "guest_profile",
            component: GuestProfile,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Guest Profile",
                permission: "view_guest",
            },
        },
        {
            path: "/bookings",
            name: "bookings",
            component: Bookings,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Bookings",
                permission: "view_booking",
            },
        },
        {
            path: "/rooms",
            name: "rooms",
            component: Rooms,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Rooms",
                permission: "view_room",
            },
        },
        {
            path: "/room/types",
            name: "room_types",
            component: RoomTypes,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Room Types",
                permission: "view_room",
            },
        },
        {
            path: "/room/types/create",
            name: "create_room_type",
            component: EditRoomType,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Create Room Type",
                permission: "create_room",
            },
        },
        {
            path: "/room/types/:id/edit",
            name: "edit_room_type",
            component: EditRoomType,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Create Room Type",
                permission: "update_room",
            },
        },
        {
            path: "/restaurant",
            name: "restaurant",
            component: Restaurant,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Restaurant",
            },
        },
        {
            path: "/services",
            name: "services",
            component: Services,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Services",
            },
        },
        {
            path: "/products/:product_type",
            name: "products",
            component: Products,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Products",
            },
        },
        {
            path: "/payments",
            name: "payments",
            component: Payments,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Payments",
                permission: "view_payment",
            },
        },
        {
            path: "/users",
            name: "users",
            component: Users,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Users",
                permission: "view_admin_user",
            },
        },
        {
            path: "/logs",
            name: "logs",
            component: Logs,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Logs",
                permission: "view_activity_log",
            },
        },
        {
            path: "/profile",
            name: "profile",
            component: Profile,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Profile",
            },
        },
        {
            path: "/settings",
            name: "hotel_settings",
            component: Settings,
            meta: {
                layout: "private",
                requiresAuth: true,
                title: "Settings",
                permission: "update_company",
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title || "App"} | Dwella`;

    let permitted = false;
    let permissions = store.state.auth.user.permissions;

    if (to.meta.permission === undefined || permissions.includes(to.meta.permission)) {
        permitted = true;
    }

    if (to.meta.requiresAuth && !store.state.auth.authenticated && permitted) {
        next({ name: "login" });
    } else if (!to.meta.requiresAuth && store.state.auth.authenticated) {
        next({ name: "dashboard" });
    }

    next();
});

export default router;