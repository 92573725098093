<template>

    <b-form
        @submit.prevent="submit"
        class="text-left"
        autocomplete="off">

        <b-row class="w-40 hor-center login-c shadow-lg border">
            <b-col class="text-left mb-4 mt-3" cols="12" sm="12" md="12" lg="12" xl="12">
                <h2 class="text-primary font-weight-bold">Login<br />to continue</h2>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <b-form-input
                    class="rounded-lg p-4"
                    type="email"
                    v-model="form.email"
                    placeholder="Enter email address"
                    trim>
                </b-form-input>
            </b-col>
            
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <b-form-input
                    class="rounded-lg p-4"
                    type="password"
                    v-model="form.password"
                    autocomplete="new-password"
                    placeholder="Enter Password"
                    trim>
                </b-form-input>
            </b-col>

            <b-col cols="6" class="mb-4">
                <b-form-checkbox
                    size="sm"
                    v-model="form.remember"
                    class="text-primary"
                    value="true"
                    unchecked-value="false">
                    <small>Remember Me</small>
                </b-form-checkbox>
            </b-col>

            <b-col cols="6" class="mb-4 text-right">
                <router-link to="/forgot-password" class="text-primary"><small>Forgotten Password</small></router-link>
            </b-col>
            
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <StatefulButton
                    :loading="submitting"
                    loadingText="Processing..."
                    text="Login"
                    elementClass="w-100 rounded-lg py-2"
                />
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-3">
                <small>Don't have an account? <router-link to="/register" class="small text-secondary text-weight-bold">Create one</router-link></small>
            </b-col>
        </b-row>

    </b-form>
</template>


<script>

    import { mapActions } from "vuex";
    import StatefulButton from "../../components/actions/StatefulButton.vue";
import { createToast } from '../../utils/helpers';

    export default {
        name: "Login-Page",
        components: {
            StatefulButton
        },
        data(){
            return {
                form: {
                    email: "",
                    password: ""
                },
                submitting: false,
                login_status:""
            }
        },
        methods : {
            ...mapActions({
                login: "auth/login"
            }),
            async submit() {
                this.submitting = true;

                this.login({ ...this.form }).then((status) => {
                    if(status) this.$router.replace({ name: 'dashboard' });
                    this.submitting = false;
                    createToast(this, "Login successful", true)
                }).catch(() => {
                    createToast(this, "Login failed")
                    this.submitting = false;
                });
            },
        }
    }
</script>

<style scoped>

   @media only screen and (max-width: 600px) {

       .login-c {
        padding:20px
       }
   }

</style>