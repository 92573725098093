<template>

    <b-sidebar id="sidebar-edit-guest" :title="title" backdrop shadow right body-class="sidebar" ref="sidebar" :lazy='true'>

        <b-overlay :show="loading" rounded="sm" variant="transparent">

            <div class="px-4 py-2">

                <form class="mt-4" @submit.prevent="submit">

                    <b-form-group label="First Name" label-for="firstname">
                        <b-form-input id="firstname" v-model="form.first_name" required />
                        <small class="text-danger" v-if="errors.first_name">{{ errors.first_name[0] }}</small>
                    </b-form-group>

                    <b-form-group label="Middle Name" label-for="middlename">
                        <b-form-input id="middlename" v-model="form.middle_name"  />
                        <small class="text-danger" v-if="errors.middle_name">{{ errors.middle_name[0] }}</small>
                    </b-form-group>

                    <b-form-group label="Last Name" label-for="lastname">
                        <b-form-input id="lastname" v-model="form.last_name" required />
                        <small class="text-danger" v-if="errors.last_name">{{ errors.last_name[0] }}</small>
                    </b-form-group>

                    <b-form-group label="Email" label-for="email">
                        <b-form-input id="email" v-model="form.email" type="email" required />
                        <small class="text-danger" v-if="errors.email">{{ errors.email[0] }}</small>
                    </b-form-group>

                    <b-form-group label="Phone Number" label-for="phonenumber">
                        <b-form-input id="phonenumber" type="tel" v-model="form.phone_number" required />
                        <small class="text-danger" v-if="errors.phone_number">{{ errors.phone_number[0] }}</small>
                    </b-form-group>

                    <b-form-group label="Gender" label-for="gender">
                        <b-form-select id="gender" v-model="form.gender" :options="['', 'male', 'female']" required />
                        <small class="text-danger" v-if="errors.gender">{{ errors.gender[0] }}</small>
                    </b-form-group>

                    <b-form-group label="Form of Identification" label-for="identification">
                        <b-form-file id="identification" v-model="form.identification" /> 
                        <small class="text-danger" v-if="errors.identification">{{ errors.identification[0] }}</small>
                    </b-form-group>

                    <b-button 
                        variant="primary"
                        type="submit"
                        class="pl-5 pr-5 mr-3">Submit
                    </b-button>

                    <b-button 
                        variant="outline-primary"
                        @click="reset()"
                        class="pl-5 pr-5">Reset
                    </b-button>

                </form>

            </div>

        </b-overlay>

    </b-sidebar>

</template>

<script>
    import { GuestService } from "@/services";
    import { createToast } from "@/utils/helpers";

    export default({
        name:'edit-guest-profile',
        props: {
            closeOnSave: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                id: this.$route.params.id,
                title: "",
                form: {
                    firstname:'',
                    middlename:'',
                    lastname:'',
                    email:'',
                    phone_number:'',
                    gender:'',
                    identification:null
                },
                loading: false,
                errors: {}
            }
        },
        methods: {
            async submit() {
                this.loading = true;
                
                try {
                    let response = this.id == undefined 
                                ? await GuestService.createGuest(this.form)
                                : await GuestService.updateGuest(this.id, this.form);
                        
                    createToast(this, response.message, true);

                    this.errors = {};

                    if(this.id === undefined) {
                        this.reset(); 
                        this.$emit('newCustomerCreated');
                    } else {
                        this.$emit('customerUpdated');
                    }

                    if(this.closeOnSave) {
                        this.$refs.sidebar.hide();
                    }

                } catch(err) {
                    this.errors = err;
                }

                this.loading = false;
            },
            async getGuest() {

                this.loading = true;

                let { data } = await GuestService.getGuest(this.id);
                this.form = data.guest;
                
                this.loading = false;
            },
            reset() {
                this.form = {
                    first_name:'',
                    middle_name:'',
                    last_name:'',
                    email:'',
                    phone_number:'',
                    gender:'',
                    identification:null
                }
            }
        },
       mounted: function() {
            //set the title of the form
            this.title = this.id === undefined ? "New Guest" : "Edit Guest Profile"
            //get the guest info to prefill the form
            if(this.id !== undefined) this.getGuest();
        }
    })
</script>

<style scoped>
    label {
        margin-bottom: 0;
        font-size: 13px;
    }
</style>