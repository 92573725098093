<template>

    <h5 class="font-weight-bold mt-3">
        <b-icon 
            @click="goBack" 
            class="mr-1 font-weight-bold rounded-lg back_btn" 
            icon="arrow-left-short" 
            font-scale="1.2" 
        /> 
        {{ title }}
    </h5>


</template>

<script>

    export default {
        name:'topbarflat',
        props: ['title'],
        methods: {
            goBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            }, 
        }
    };

</script>

<style scoped>

    .back {
        font-family: 'Circular Std';
        font-weight: 600;
        cursor: pointer;
    }

    .back_btn:hover {
        background-color: #ebeff7;
        cursor: pointer;
    }

</style>