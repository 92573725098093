import { AuthService, UserService, } from "../services";

export default {
    namespaced: true,

    state: {
        authenticated: false,
        user: {},
        token: null,
    },

    getters: {
        authenticated(state) {
            return state.authenticated
        },

        user(state) {
            return state.user;
        },

        role(state) {
            return state.user ? state.user.role : "";
        },

        company(state) {
            return state.user ? state.user.company : null;
        },
    },

    mutations: {
        SET_TOKEN(state, data) {
            state.token = data;
        },
        SET_USER(state, data) {
            state.user = data;
        },
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value;
        }
    },

    actions: {

        async login({ dispatch }, { email, password }) {
            let { data } = await AuthService.login({ email, password });

            //get the user info
            return dispatch('attempt', data.token);
        },

        async attempt({ commit, state }, token) {

            //set the token if token is not null
            if (token) commit('SET_TOKEN', token);

            //quit if there is no token in the state
            if (!state.token) return;

            //get user info
            try {
                const { data } = await UserService.getProfile();

                commit('SET_USER', data.user);
                commit('SET_AUTHENTICATED', true);

                console.log("here " + state.token)

                return true;
            } catch (e) {
                commit('SET_USER', {});
                commit('SET_AUTHENTICATED', false);

                return false;
            }
        },

        logout({ commit }) {
            return AuthService.logout().then(() => {
                commit('SET_USER', {});
                commit('SET_TOKEN', null);
                commit('SET_AUTHENTICATED', false);
            })
        }
    }
};