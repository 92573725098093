import Vue from "vue";
import { resolveRequestError } from "../utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class PaymentService {

    static async createPayment(payment_data) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_PAYMENT, payment_data);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchPayments(request_params) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.PAYMENTS, request_params);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async verifyPayment(id) {
        try {
            const { data } = await Vue.http.patch(ENDPOINTS.VERIFY_PAYMENT(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async approvePayment(id) {
        try {
            const { data } = await Vue.http.patch(ENDPOINTS.APPROVE_PAYMENT(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async disapprovePayment(id, reason) {
        try {
            const { data } = await Vue.http.patch(ENDPOINTS.DISAPPROVE_PAYMENT(id), { remark: reason });
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }
}