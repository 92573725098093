import app from "../main";
import { createToast } from "./helpers";

function errorHandler(e) {
    let data = "Connection seems to be very slow";
    if (e.response && (e.response.status === 400 || e.response.status === 422)) {
        data = e.response.data;
    } else if (e.response && e.response.status === 401) {
        data = "Your are not logged in";
        localStorage.clear();
        if (document.location.pathname == "/login") {
            app.$router.replace({
                name: "login"
            });
        } else {
            app.$router.replace({
                path: `/login?next=${document.location.pathname}`
            });
        }
    } else if (e.response && e.response.status === 403) {
        data = e.response.data;
        app.$store.dispatch("toast", {
            message: data.message,
            variant: "warning"
        });
        app.$store.dispatch("user/updateProfile", data.user);
        app.$router.replace({
            name: "onboarding"
        });
    } else if (e.response && e.response.status === 404) {
        data = "Oops! an error occured ";
    } else if (e.response && e.response.status === 500) {
        data = "Oops! an error occured";
    }
    const status = e.response ? e.response.status : 0;
    return {
        status,
        data
    };
}

/**
 * Handles how error is displayed to the user
 * @param {Error} error object
 * @param {Boolean} throwAll
 * @param {Boolean} popToast
 */
function resolveRequestError(err, throwAll = true, popToast = true) {

    const { status, data } = errorHandler(err);

    // if form validation errors are present, throw it so the caller can catch it
    if (data.errors) throw data.errors;

    if ([401, 403].includes(status)) {
        return status;
    }

    let text = data.message;
    if (!text) text = data;
    if (popToast) createToast(text);

    if (status === 0 && !popToast) createToast(text);

    if (throwAll) throw data;
}

export { resolveRequestError };