<template>
    <div>
        <b-card class="p-2">
            <b-row>
                <b-col>
                    <h5 class="font-weight-bold">User List</h5>
                </b-col>
                <b-col class="text-right">
                    <b-button variant="primary">
                    <b-icon icon="person-plus" aria-hidden="true" class="icon"></b-icon>
                    New User
                    </b-button>
                </b-col>
            </b-row>

            <b-table small responsive :fields="fields" :items="items" class="mt-4 table-fit">
                <template #cell(user)="data">
                    <span class="font-weight-bold">{{ data.item.first_name + " " + data.item.last_name }}</span>
                </template>

                <template #cell(status)="data">
                    <span class="font-weight-bold">
                        <b-badge v-if="data.item.is_active" class="badge-light-success">Active</b-badge>
                        <b-badge v-if="!data.item.is_active" class="badge-light-danger">Disabled</b-badge>
                    </span>
                </template>

                <template #cell(role)="data">
                    <b-badge class="font-weight-bold border" variant="light">{{ data.item.role }}</b-badge>
                </template>

                <template #cell(action)="data">
                    <b-button-group size="sm">
                        <b-button variant="light" class="border text-primary font-weight-bold" @click="activateUser(data.item)" v-if="!data.item.is_active">
                            <b-icon icon="pencil-fill" font-scale="0.8" shift-v="6" class="icon mr-2" />Activate
                        </b-button>
                        <b-button variant="light" class="border text-danger font-weight-bold" @click="deactivateUser(data.item)" v-if="data.item.is_active">
                            <b-icon icon="x-square-fill" font-scale="0.8" shift-v="6" class="icon mr-2" />Deactivate
                        </b-button>
                    </b-button-group>
                </template>
            </b-table>
        </b-card>

        <b-row v-show="data.total">
            <b-col class="mt-4 font-weight-bold">{{ formatNumber(data.from) + " to " + formatNumber(data.to) + " of " + formatNumber(data.total) }} Users</b-col>
            <b-col><b-pagination v-model="data.current_page" :total-rows="data.total" :per-page="data.per_page" align="right" class="mt-3" @input="fetchUsers"></b-pagination></b-col>
        </b-row>
    </div>    
</template>

<script>
    import {formatNumber} from '../../../utils/helpers.js';
    import { UsersService } from '@/services';
    import { createToast } from '@/utils/helpers';

    export default({
        name:'users-table',
        methods: {
            formatNumber,
            filter(filter_params) {
                this.request_params = filter_params;
                this.fetchUsers();
            },
            async fetchUsers(page = 1) {
                this.current_page = page;
                let { data } = await UsersService.fetchUsers({page:page, ...this.request_params});
                this.data = data;
                this.items = data.data;
            },
            deactivateUser(user) {
                this.$bvModal.msgBoxConfirm('Please confirm that you really want to deactivate ' + user.first_name + ' ' + user.last_name, {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    cancelVariant: 'light',
                    okTitle: 'Deactivate',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if(value) {
                        UsersService.deactivateUser(user.id).then(data => {
                            createToast(this, data.message, data.success);
                            this.fetchUsers();
                        })
                    }
                })
            },
            activateUser(user) {
                this.$bvModal.msgBoxConfirm('Please confirm that you really want to activate ' + user.first_name + ' ' + user.last_name, {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    cancelVariant: 'light',
                    okTitle: 'Activate',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if(value) {
                        UsersService.activateUser(user.id).then(data => {
                            createToast(this, data.message, data.success);
                            this.fetchUsers();
                        })
                    }
                })
            },
        },
        data: () => {
            return {
                data: {},
                items: [],
                request_params: {},
                current_page: 0,
                fields: ['user', 'role', 'email', 'status',  'action'],
            }
        }
    })
</script>

<style scoped></style>