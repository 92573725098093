import Vue from "vue";
import { resolveRequestError } from "../utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class ProductService {
    static async fetchServiceProducts() {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.SERVICE_PRODUCTS);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createServiceProduct(product) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_SERVICE_PRODUCT, product);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchRestaurantProducts() {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.RESTAURANT_PRODUCTS);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createRestaurantProduct(product) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_RESTAURANT_PRODUCT, product);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async updateProduct(id, product) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.UPDATE_PRODUCT(id), product);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getProduct(id) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.GET_PRODUCT(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchServiceProductCategories() {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.SERVICE_PRODUCT_CATEGORIES);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createServiceProductCategories(category) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_SERVICE_PRODUCT_CATEGORIES, category);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async fetchRestaurantProductCategories() {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.RESTAURANT_PRODUCT_CATEGORIES);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createRestaurantProductCategories(category) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_RESTAURANT_PRODUCT_CATEGORIES, category);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async updateProductCategory(id, category) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.UPDATE_PRODUCT_CATEGORY(id), category);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getProductCategory(id) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.GET_PRODUCT_CATEGORY(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }
}