<template>

    <div>

        <TopBar title="Profile" />



    </div>

</template>

<script>

    import TopBar from "../../components/TopBar.vue";

    import { mapState } from "vuex";

    export default{
        name:'profile',
        components: {
            TopBar
        },
        computed: {
            ...mapState(['userObj'])
        }
    }
</script>

<style scoped>


   

</style>