export const formatPercent = number => {
    const formatter = new Intl.NumberFormat(`en-NG`, {
        style: "percent",
        minimumFractionDigits: 2
    });
    return formatter.format(number);
};

/**
 * Formats money
 * @param {String} amount
 * @param {Boolean} addSign
 */
export const formatMoney = (number = 0, countryCode = "NG", currencyCode = "NGN") => {

    if (isNaN(number * 1)) {
        number = 0;
    }

    const formatter = new Intl.NumberFormat(`en-${countryCode}`, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 2
    });
    return formatter.format(number);
};


/**
 * Captilize first letter of a string
 *
 * @param {String} str
 */
export const capitalizeFirstLetter = str => {
    if (str === null) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
};


export const makeSuccessToast = (context, message, title = "Success!") => {
    context.$bvToast.toast(message, {
        title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'success'
    })
}


export const makeErrorToast = (context, message, title = "Error!") => {
    context.$bvToast.toast(message, {
        title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'danger'
    })
}

export const createToast = (context, message, status = false) => {
    status == false ? makeErrorToast(context, message) : makeSuccessToast(context, message);
}

export const smoothScroll = (duration = 1000) => {
    let cosParameter = window.scrollY / 2,
        scrollCount = 0,
        oldTimestamp = performance.now();

    function step(newTimestamp) {
        scrollCount += Math.PI / (duration / (newTimestamp - oldTimestamp));
        if (scrollCount >= Math.PI) window.scrollTo(0, 0);
        if (window.scrollY === 0) return;
        window.scrollTo(
            0,
            Math.round(cosParameter + cosParameter * Math.cos(scrollCount))
        );
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }

    window.requestAnimationFrame(step);
};

export const formatNumber = (value, decimal_places = 0) => {
    return new Intl.NumberFormat().format(value, decimal_places);
}

import nigerianBanks from "./nigerianBanks";
export const bankLogo = code => {
    const name = nigerianBanks[code].toLowerCase().replace(/\s/g, "_");
    return require(`../assets/icons/bank-logos/${name}.svg`);
};

export const titleCase = string => {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
}

export const isLoggedIn = () => {
    return localStorage.getItem('authToken') != undefined;
}