<template>

    <div>
        <edit-booking :closeOnSave="true" @bookingSaved="getBookings" :booking="selected_booking" :lazy="true" />
        <payment-form :closeOnSave="true" @paymentSaved="getBookings" :payment_entity="payment_entity" />

        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card class="p-2">
                    <div class="text-right">
                        <b-skeleton type="button" width="15%" class="mb-4 right"></b-skeleton>
                    </div>

                    <b-skeleton-table
                        :rows="10"
                        :columns="5">
                    </b-skeleton-table>
                </b-card>
            </template>

            <b-card class="p-2">
                <b-row>
                    <b-col>
                        <h5 class="font-weight-bold">Booking List</h5>
                    </b-col>
                    <b-col class="text-right" v-if="user.permissions.includes('create_booking')">
                        <b-button variant="primary" class="pt-1 pb-1" v-b-toggle.sidebar-edit-booking @click.prevent @click="selected_booking = {}">
                            <b-icon icon="calendar2-plus" aria-hidden="true" class="icon"></b-icon>
                            New Booking
                        </b-button>
                    </b-col>
                </b-row>
                
                <b-table small responsive :fields="fields" :items="items" class="mt-4 table-fit">

                    <template #cell(guests)="data">
                        <router-link :to="`/guests/profile/${data.item.principal_guest.id}`">
                            <strong>{{  data.item.principal_guest.first_name + " " + data.item.principal_guest.last_name }}</strong>
                        </router-link> <sup v-if="data.item.guests.length > 1">+{{ data.item.guests.length-1 }}</sup>
                    </template>

                    <template #cell(amount)="data">
                        <div class="mt-1">{{ formatMoney(data.item.amount) }} 
                            <span v-if="data.item.amount_paid >= data.item.amount"><b-icon icon="check2-square" variant="success" class="icon" font-scale="1.2" /></span>
                        </div>
                        <small class="d-block font-weight-bold" v-bind:class="[ data.item.amount_paid >= data.item.amount ? 'text-success' : 'text-mute' ]">{{ formatMoney(data.item.amount_paid) }} Paid</small>
                    </template>

                    <!-- Optional default data cell scoped slot -->
                    <template #cell(booking)="data">
                        <div class="font-weight-bold">#{{ data.item.code }}</div>
                        <small class="text-muted font-weight-bold">{{ data.item.room_type.name }}<span v-if="data.item.room">, {{ "Room " + data.item.room.name }}</span></small>
                    </template>

                    <template #cell(status)="data">
                        <div class="font-weight-bold">
                            <b-badge v-if="data.item.status=='checked_in'" class="badge-light-primary">Checked In</b-badge>
                            <b-badge v-if="data.item.status=='checked_out'" class="badge-light-success">Checked Out</b-badge>
                            <b-badge v-if="data.item.status=='pending'" class="badge-light-warning">Pending</b-badge>
                            <b-badge v-if="data.item.status=='cancelled'" class="badge-light-danger">Cancelled</b-badge>
                        </div>
                        <small class="text-muted">{{ data.item.check_in_date | moment("DD MMM YYYY") }} - {{ data.item.check_out_date | moment("DD MMM YYYY") }}</small>
                    </template>

                    <template #cell(action)="data">
                        <b-button-group size="sm">
                            <b-button variant="light" class="border text-primary font-weight-bold" v-if="data.item.status=='pending' && user.permissions.includes('update_booking')" v-b-toggle.sidebar-edit-booking @click="selected_booking = data.item">
                                <b-icon icon="pencil-fill" font-scale="0.8" shift-v="6" class="icon mr-2" />Update
                            </b-button>
                            <b-button variant="light" class="border text-success font-weight-bold" v-if="data.item.amount_paid<data.item.amount && data.item.status !== 'cancelled'  && user.permissions.includes('create_payment')" @click="openPaymentForm(data.item)">
                                <b-icon icon="credit-card2-back-fill" class="icon mr-2" font-scale="0.8" shift-v="6" />Pay
                            </b-button>
                            <b-button variant="light" class="border text-danger font-weight-bold" v-if="data.item.status=='pending' && data.item.amount_paid==0 && user.permissions.includes('cancel_booking')" @click="cancel(data.item)">
                                <b-icon icon="x-square-fill" class="icon mr-2" font-scale="0.8" shift-v="6" />Cancel
                            </b-button>
                        </b-button-group>
                    </template>

                </b-table>

            </b-card>

            <b-row v-show="data.total">
                <b-col class="mt-4 font-weight-bold">{{ formatNumber(data.from) + " to " + formatNumber(data.to) + " of " + formatNumber(data.total) }} Bookings</b-col>
                <b-col><b-pagination v-model="data.current_page" :total-rows="data.total" :per-page="data.per_page" align="right" class="mt-3" @input="getBookings"></b-pagination></b-col>
            </b-row>

        </b-skeleton-wrapper>
    </div>
            
</template>

<script>

    import {formatMoney, formatNumber} from '@/utils/helpers.js';
    import EditBooking from '../../../components/EditBooking';
    import PaymentForm from '../../../components/PaymentForm';
    import { BookingService } from "@/services";
    import { createToast } from '@/utils/helpers';
    import { mapGetters } from 'vuex'

    export default({
        name:'bookings-table',
        methods: {
            formatMoney,
            formatNumber,
            filter(filter_params) {
                this.request_params = filter_params;
                this.getBookings(1);
            },
            async getBookings(page = 1) {
                this.loading = true;
                this.current_page = page;
                let { data } = await BookingService.fetchBookings({page:page, ...this.request_params});
                this.data = data;
                this.items = data.data;
                this.loading = false;
            },
            cancel(booking) {
                this.$bvModal.msgBoxConfirm('Please confirm that you really want to cancel booking #' + booking.code, {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if(value) {
                        BookingService.cancelBooking(booking.id).then(data => {
                            createToast(this, data.message, data.success);
                            this.getBookings();
                        })
                    }
                })
            },
            openPaymentForm(booking) {
                //set the payment entity
                this.payment_entity = {
                    entity: 'bookings',
                    user_id: booking.principal_guest.id,
                    entity_id: booking.id,
                    amount: booking.amount
                }
                //open the sidebar
                this.$root.$emit('bv::toggle::collapse', 'payment-form-sidebar')
            }
        },
        components: {
            EditBooking,
            PaymentForm
        },
        data: () => {
            return {
                loading:true,
                data: {},
                items: [],
                request_params: {},
                current_page: 0,
                fields: ['guests', 'booking', 'amount', 'status', 'action'],
                selected_booking: {},
                payment_entity: {}
            }
        },
        created() {
            this.getBookings();
        },
        computed: {
            ...mapGetters({
                user: "auth/user",
            })
        }
    })
</script>

<style scoped></style>