<template>

    <div>
        <edit-booking :closeOnSave="true" @bookingSaved="getBookings" :booking="selected_booking" :lazy="true" />
        <payment-form :closeOnSave="true" @paymentSaved="getBookings" :payment_entity="payment_entity" />

        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card class="p-2">
                    <div class="text-right">
                        <b-skeleton type="button" width="15%" class="mb-4 right"></b-skeleton>
                    </div>

                    <b-skeleton-table
                        :rows="3"
                        :columns="3">
                    </b-skeleton-table>
                </b-card>
            </template>

            <b-card class="p-2">
                <div class="text-right" v-if="user.permissions.includes('create_booking')">
                    <b-button variant="primary" class="pt-1 pb-1" v-b-toggle.sidebar-edit-booking @click.prevent @click="selected_booking = {}">
                        <b-icon icon="calendar2-plus" aria-hidden="true" class="icon"></b-icon>
                        New Booking
                    </b-button>
                </div>
                
                <b-table small responsive :fields="fields" :items="bookings" class="mt-4 table-fit">

                    <template #cell(amount)="data">
                        <div class="mt-1">{{ formatMoney(data.item.amount) }} 
                            <span v-if="data.item.amount_paid >= data.item.amount"><b-icon icon="check2-square" variant="success" class="icon" font-scale="1.2" /></span>
                        </div>
                        <small class="d-block font-weight-bold" v-bind:class="[ data.item.amount_paid >= data.item.amount ? 'text-success' : 'text-mute' ]">{{ formatMoney(data.item.amount_paid) }} Paid</small>
                    </template>

                    <!-- Optional default data cell scoped slot -->
                    <template #cell(booking)="data">
                        <div class="font-weight-bold">#{{ data.item.code }}</div>
                        <small class="text-muted font-weight-bold">{{ data.item.room_type.name }}<span v-if="data.item.room">, {{ "Room " + data.item.room.name }}</span></small>
                    </template>

                    <template #cell(status)="data">
                        <div class="font-weight-bold">
                            <b-badge v-if="data.item.status=='checked_in'" class="badge-light-primary">Checked In</b-badge>
                            <b-badge v-if="data.item.status=='checked_out'" class="badge-light-success">Checked Out</b-badge>
                            <b-badge v-if="data.item.status=='pending'" class="badge-light-warning">Pending</b-badge>
                            <b-badge v-if="data.item.status=='cancelled'" class="badge-light-danger">Cancelled</b-badge>
                        </div>
                        <small class="text-muted">{{ data.item.check_in_date | moment("DD MMM YYYY") }} - {{ data.item.check_out_date | moment("DD MMM YYYY") }}</small>
                    </template>

                    <template #cell(action)="data">
                        <b-button-group size="sm">
                            <b-button variant="light" class="border text-primary font-weight-bold" v-if="data.item.status=='pending'" v-b-toggle.sidebar-edit-booking @click="selected_booking = data.item">
                                <b-icon icon="pencil-fill" font-scale="0.8" shift-v="6" class="icon mr-2" />Update
                            </b-button>
                            <b-button variant="light" class="border text-success font-weight-bold" v-if="data.item.amount_paid<data.item.amount && data.item.status !== 'cancelled'" @click="openPaymentForm(data.item)">
                                <b-icon icon="credit-card2-back-fill" class="icon mr-2" font-scale="0.8" shift-v="6" />Pay
                            </b-button>
                            <b-button variant="light" class="border text-danger font-weight-bold" v-if="data.item.status=='pending' && data.item.amount_paid==0" @click="cancel(data.item)">
                                <b-icon icon="x-square-fill" class="icon mr-2" font-scale="0.8" shift-v="6" />Cancel
                            </b-button>
                        </b-button-group>
                    </template>

                </b-table>

            </b-card>

        </b-skeleton-wrapper>
    </div>
            
</template>

<script>

    import {formatMoney, formatNumber} from '@/utils/helpers.js';
    import EditBooking from '../../../components/EditBooking';
    import PaymentForm from '../../../components/PaymentForm';
    import { GuestService, BookingService } from "@/services";
    import { createToast } from '@/utils/helpers';
    import { mapGetters } from 'vuex';

    export default({
        name:'bookings-table',
        props: ['id'],
        methods: {
            formatMoney,
            formatNumber,
            async getBookings() {
                this.loading = true;
                let {data} = await GuestService.getBookings(this.id);
                this.bookings = data.bookings;
                this.loading = false
            },
            cancel(booking) {
                this.$bvModal.msgBoxConfirm('Please confirm that you really want to cancel booking #' + booking.code, {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if(value) {
                        BookingService.cancelBooking(booking.id).then(data => {
                            createToast(this, data.message, data.success);
                            this.getBookings();
                        })
                    }
                })
            },
            openPaymentForm(booking) {
                //set the payment entity
                this.payment_entity = {
                    entity: 'bookings',
                    user_id: this.id ?? booking.principal_guest_id,
                    entity_id: booking.id,
                    amount: booking.amount
                }
                //open the sidebar
                this.$root.$emit('bv::toggle::collapse', 'payment-form-sidebar')
            }
        },
        components: {
            EditBooking,
            PaymentForm
        },
        data: () => {
            return {
                loading:true,
                bookings: [],
                fields: ['booking', 'amount', 'status', 'action'],
                selected_booking: {},
                payment_entity: {}
            }
        },
        created() {
            this.getBookings();
        },
        computed: {
            ...mapGetters({
                user: "auth/user",
            })
        }
    })
</script>

<style scoped></style>