<template>

	<b-container fluid>

		<div class="d-none d-block d-lg-none">
			
			<div class="w-100 p-3">
				<slot />
			</div>
			
		</div>

		<div class="d-none d-lg-block">
			<b-row class="w-100 mr-0 ml-0">
				<b-col cols="2" class="sidebar p-0">
					<sidebar class="position-fixed" />
				</b-col>
				<b-col cols="10" class="content">
					<slot />
				</b-col>
			</b-row>
		</div>

	</b-container>

</template>

<script>

	import Sidebar from "../components/Sidebar.vue";

	export default {
		name: "private",
        components: {
            Sidebar
        },
	}

</script>

<style scoped>

	.mnav {
		font-size:25px
	}

	.sidebar {
		width: 16.67%;
		height:100vh;
	}

	.position-fixed {
		height:100vh;
		box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
		background: #fff;
		z-index: 2;
	}

	
</style>
