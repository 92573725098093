<template>

    <div>

        <TopBar :title="title" :hasBackButton="true" />

        <b-row class="mt-4">
            <b-col cols="12" sm="12" md="9">
                <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                        <b-card>
                            <b-skeleton width="100%" height="30px" class="mb-2"></b-skeleton>
                            <b-skeleton width="55%" height="30px" class="mb-2"></b-skeleton>
                            <b-skeleton width="75%" height="30px" class="mb-2"></b-skeleton>
                            <b-skeleton width="40%" height="30px"></b-skeleton>
                        </b-card>
                    </template>

                    <b-card v-if="!loading">
                        <b-row>
                            <b-col class="d-flex flex-fill" cols="12" sm="12" md="6">
                                <div>
                                    <div class="d-flex">
                                        <img :src="guest_avatar" width="100" height="100" class="border" />
                                        <div class="ml-3">
                                            <div class="mb-2"><b-icon icon="envelope" class="icon mr-2" /> Email : <b>{{guest.email}}</b></div>
                                            <div class="mb-2"><b-icon icon="telephone" class="icon mr-2" /> Phone Number : <b>{{guest.phone_number}}</b></div> 
                                            <div class="mb-2"><b-icon icon="person" class="icon mr-2" /> Gender : <b>{{guest.gender}}</b></div> 
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-button variant="primary" v-if="user.permissions.includes('update_guest')" class="pl-4 pr-4 btn" v-b-toggle.sidebar-edit-guest @click.prevent>Edit</b-button>
                    </b-card>

                </b-skeleton-wrapper>
            </b-col>

            <b-col cols="12" sm="12" md="3">

                <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                        <b-card>
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="100%" class="mb-3"></b-skeleton>
                        <b-skeleton width="55%" height="30px" class="mb-3"></b-skeleton>
                        <b-skeleton width="100%" height="50px" type="button"></b-skeleton>
                        </b-card>
                    </template>

                    <b-card class="border border-primary" style="min-height:190px">
                        <div v-if="last_booking.id !== undefined">
                            <h6 class="d-flex justify-content-between">
                                <div>Latest Booking</div>
                                <b-badge class="badge-light-warning" v-if="last_booking.status=='pending'">Pending</b-badge>  
                                <b-badge class="badge-light-primary" v-if="last_booking.status=='checked_in'">Checked In</b-badge>  
                                <b-badge class="badge-light-success" v-if="last_booking.status=='checked_out'">Checked Out</b-badge>  
                                <b-badge class="badge-light-danger" v-if="last_booking.status=='cancelled'">Cancelled</b-badge>  
                            </h6>
                            <div class="text-muted" style="margin-top:-20px"><small>Mar 24th, 2022 - Mar 28th, 2022</small></div>

                            <div class="mt-2"><b>{{formatMoney(last_booking.amount)}}</b></div> 
                            <small><b>{{last_booking.room_type.name}}</b> - {{last_booking.guests.length}} Guests</small>
                            
                            <b-button variant="primary" class="w-100">View Details</b-button>
                        </div>
                        <div class="text-center font-weight-bold" v-else>No Bookings</div>
                    </b-card>
                </b-skeleton-wrapper>
            </b-col>
        </b-row>

        <b-row class="mt-4">
            <b-col cols="12" sm="12" md="8">
                <b-tabs>
                    <b-tab active>
                        <template #title>
                            <div><b-icon icon="calendar2-minus" class="icon" /> Bookings</div>
                        </template>
                        <bookings-table show-guest="false" :id="id" />
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <div><b-icon icon="cup-straw" class="icon" /> Restaurant</div>
                        </template>
                        <orders type="restaurant" :id="id" />
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <div><b-icon icon="droplet" class="icon" /> Services</div>
                        </template>
                        <orders type="service" :id="id" />
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <div><b-icon icon="wallet2" class="icon" /> Wallet</div>
                        </template>
                        
                    </b-tab>
                </b-tabs>
            </b-col>
            <b-col cols="12" sm="12" md="4">
                <b-card style="height:500px">
                    <h6><b-icon icon="clock-history" /> Timelines</h6>
                    <hr />
                    
                </b-card>
            </b-col>
        </b-row>

        <edit-profile @customerUpdated="fetchProfile()" :close-on-save=true />

    </div>

</template>

<script>

    import TopBar from "@/components/TopBar.vue";
    import { formatMoney } from "@/utils/helpers.js";
    import BookingsTable from "./components/BookingsTable.vue";
    import Orders from "./components/Orders.vue";
    /**import Timeline from "./components/Timeline.vue";
    import Transactions from "./components/Transactions.vue";**/
    import EditProfile from "./components/EditProfile.vue";
    import { GuestService } from "@/services";
    import { mapGetters } from 'vuex';

    export default {
        name:'guest-profile',
        components: {
            TopBar,
            BookingsTable,
            Orders,
            /**Transactions,
            Timeline,**/
            EditProfile
        },
        data() {
            return {
                id: this.$route.params.id,
                title: "",
                loading:true,
                guest_avatar: require("../../assets/icons/avatar.svg"),
                guest:{},
                last_booking:{},
            }
        },
        methods: {
            formatMoney,
            async fetchProfile() {
                let { data } = await GuestService.getGuest(this.id);
                this.guest = data.guest;
                this.title = this.guest.first_name + " " + this.guest.last_name;
            },
            async getLastBooking() {
                let {data} = await GuestService.getLastBooking(this.id);
                this.last_booking = data.booking;
            }
        },
        created() {
            this.fetchProfile();
            this.getLastBooking();

            this.loading = false;
        },
        computed: {
            ...mapGetters({
                user: "auth/user",
            })
        }

    }
</script>

<style scoped>

    .btn {
        margin-top:14px !important
    }

    .tab-content {
        background-color:#fff;
        height:500px;
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    }
</style>