import Vue from "vue";
import { resolveRequestError } from "../utils/auth";
import * as ENDPOINTS from "./endpoints";

export default class GuestService {


    static async getGuests(filter_arr) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.GUESTS, filter_arr);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async createGuest(guest_data) {
        try {
            const { data } = await Vue.http.post(ENDPOINTS.CREATE_GUEST, guest_data);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getGuest(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.GUEST_PROFILE(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async updateGuest(id, guest_data) {
        try {
            const { data } = await Vue.http.put(ENDPOINTS.UPDATE_GUEST(id), guest_data);
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getBookings(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.GUEST_BOOKINGS(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getLastBooking(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.GUEST_LAST_BOOKING(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getOrders(id, type) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.GUEST_ORDERS(id, type));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getActivityLogs(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.GUEST_ACTIVITIES(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getWallet(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.GUEST_WALLET(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }

    static async getWalletTransactions(id) {
        try {
            const { data } = await Vue.http.get(ENDPOINTS.GUEST_WALLET_TRANSACTIONS(id));
            return data;
        } catch (err) {
            resolveRequestError(err);
        }
    }
}