<template>
  <div>
    <div class="w-50 mt-4" style="margin:0 auto">
      <top-bar-flat :title="!$route.params.id ? 'Create Room Type' : 'Edit Room Type'" class="mb-3"/>

      <b-card>
        <b-form-group label="Name" label-for="name">
          <b-form-input id="name" v-model="form.name" />
          <small class="text-danger" v-if="errors.name">{{ errors.name[0] }}</small>
        </b-form-group>

        <b-form-group label="Description" label-for="description">
          <quill-editor ref="quillEditor" class="editor" v-model="form.description" />
          <small class="text-danger" v-if="errors.description">{{ errors.description[0] }}</small>
        </b-form-group>

        <b-form-group label="Features - e.g wifi, complimentary breakfast etc" label-for="features">
          <b-form-textarea id="features" v-model="form.features" />
          <small class="text-danger" v-if="errors.features">{{ errors.features[0] }}</small>
        </b-form-group>

        <b-form-group label="Images" label-for="images">
          <b-form-file accept="image/jpeg, image/png, image/gif" multiple v-model="form.images" />
          <small class="text-danger" v-if="errors.images">{{ errors.images[0] }}</small>
        </b-form-group>

        <div class="d-flex">
          <b-form-group label="Dimension" label-for="dimension" class="mr-2">
            <b-form-input id="dimension" v-model="form.dimension" type="number" />
            <small class="text-danger" v-if="errors.dimension">{{ errors.dimension[0] }}</small>
          </b-form-group>

          <b-form-group label="Rate Per Night" label-for="rate">
            <b-form-input id="rate" v-model="form.rate" type="number" />
            <small class="text-danger" v-if="errors.rate">{{ errors.rate[0] }}</small>
          </b-form-group>
        </div>

        <hr />

        <stateful-button
          variant="primary"
          :loading="loading"
          loadingText=""
          text="Submit"
          type="button"
          elementClass="px-5 mr-3 mt-2"
          @click="submit">
        </stateful-button>

        <b-button variant="outline-primary" class="px-5 mt-2 float-right" @click="reset">Reset</b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import { RoomService } from "@/services";
import StatefulButton from "../../../components/actions/StatefulButton.vue";
import TopBarFlat from "../../../components/TopBarFlat.vue";
import { createToast } from "@/utils/helpers";

export default {
  name: "edit-booking",
  components: {
    TopBarFlat,
    StatefulButton,
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        description: "",
        features: "",
        images: [],
        dimension: "",
        rate: "",
      },
      variant: "dark",
      errors: {},
    };
  },

  watch: {
    "form.images"(val) {
      console.log(val);
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.getRoomType()
    } 
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
          let response = this.$route.params.id == undefined 
                      ? await RoomService.createRoomType(this.form)
                      : await RoomService.updateRoomType(this.$route.params.id, this.form)
              
          createToast(this, response.message, true);

          this.errors = {};

          if(this.$route.params.id == undefined) {
              this.reset(); 
          }

      } catch(err) {
          this.errors = err;
      }
      this.loading = false;
    },
    reset() {
      this.form =  {
        name: "",
        description: "",
        features: "",
        images: [],
        dimension: "",
        rate: "",
      }
    },
    async getRoomType(){
      let {data} = await RoomService.getSingleRoomTypes(this.$route.params.id)
      this.form = data.room_type;
      this.form.images = JSON.parse(this.form.images)
    }
  },
};
</script>

<style scoped>
.editor {
  background-color: #fff;
}
</style>
