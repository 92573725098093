const AUTH = "/auth";

export const REGISTER = `${AUTH}/register`;
export const VERIFY_CODE = (code) => `${AUTH}/verify/${code}`;
export const LOGIN = `${AUTH}/login`;
export const LOGOUT = `${AUTH}/logout`;
export const SEND_RESET_TOKEN = `${AUTH}/send-reset-token`;
export const RESET_PASSWORD = (code) => `${AUTH}/reset/${code}`;
export const SEND_VERIFICATION_CODE = `${AUTH}/send-verification-code`;

export const PROFILE = "profile";

export const BOOKINGS = "/bookings";
export const CREATE_BOOKING = `${BOOKINGS}/create`;
export const UPDATE_BOOKING = (id) => `${BOOKINGS}/${id}`;
export const CANCEL_BOOKING = (id) => `${BOOKINGS}/${id}/cancel`;
export const BOOKING_DETAILS = (id) => `${BOOKINGS}/${id}`;
export const FETCH_AVAILABLE_ROOMS = (id) => `${BOOKINGS}/${id}/available-rooms`;
export const ASSIGN_ROOM = (id) => `${BOOKINGS}/${id}/assign-room`;

const ROOM = "/room";
export const ROOMS = "/rooms";
export const CREATE_ROOM = `${ROOMS}/create`;
export const UPDATE_ROOM = (id) => `${ROOMS}/${id}`;
export const ROOM_TYPES = `${ROOM}/types`;
export const SINGLE_ROOM_TYPE = (id) => `${ROOM}/types/${id}`;
export const CREATE_ROOM_TYPE = `${ROOM}/types/create`;
export const UPDATE_ROOM_TYPE = (id) => `${ROOM_TYPES}/${id}`;

export const GUESTS = "/guests";
export const CREATE_GUEST = `${GUESTS}/create`;
export const GUEST_PROFILE = (id) => `${GUESTS}/${id}`;
export const UPDATE_GUEST = (id) => `${GUESTS}/${id}`;
export const GUEST_BOOKINGS = (id) => `${GUESTS}/${id}/bookings`;
export const GUEST_LAST_BOOKING = (id) => `${GUESTS}/${id}/last-booking`;
export const GUEST_ORDERS = (id, type) => `${GUESTS}/${id}/orders/${type}`;
export const GUEST_ACTIVITIES = (id) => `${GUESTS}/${id}/activity-logs`;
export const GUEST_WALLET = (id) => `${GUESTS}/${id}/wallet-balance`;
export const GUEST_WALLET_TRANSACTIONS = (id) =>
    `${GUESTS}/${id}/wallet-transactions`;

const ORDERS = "orders";
export const RESTAURANT_ORDERS = `/restaurant/${ORDERS}`;
export const CREATE_RESTAURANT_ORDER = `${RESTAURANT_ORDERS}/create`;
export const UPDATE_RESTAURANT_ORDER = (id) => `${RESTAURANT_ORDERS}/${id}`;
export const GET_RESTAURANT_ORDER = (id) => `${RESTAURANT_ORDERS}/${id}`;
export const CANCEL_RESTAURANT_ORDER = (id) => `${RESTAURANT_ORDERS}/${id}/cancel`;

export const SERVICE_ORDERS = `/service/${ORDERS}`;
export const CREATE_SERVICE_ORDER = `${SERVICE_ORDERS}/create`;
export const UPDATE_SERVICE_ORDER = (id) => `${SERVICE_ORDERS}/${id}`;
export const GET_SERVICE_ORDER = (id) => `${SERVICE_ORDERS}/${id}`;
export const CANCEL_SERVICE_ORDER = (id) => `${SERVICE_ORDERS}/${id}/cancel`;

const PRODUCTS = "products";
export const RESTAURANT_PRODUCTS = `/restaurant/${PRODUCTS}`;
export const CREATE_RESTAURANT_PRODUCT = `${RESTAURANT_PRODUCTS}/create`;
export const UPDATE_RESTAURANT_PRODUCT = (id) => `${RESTAURANT_PRODUCTS}/${id}`;
export const GET_RESTAURANT_PRODUCT = (id) => `${RESTAURANT_PRODUCTS}/${id}`;

export const SERVICE_PRODUCTS = `/service/${PRODUCTS}`;
export const CREATE_SERVICE_PRODUCT = `${SERVICE_PRODUCTS}/create`;
export const UPDATE_SERVICE_PRODUCT = (id) => `${SERVICE_PRODUCTS}/${id}`;
export const GET_SERVICE_PRODUCT = (id) => `${SERVICE_PRODUCTS}/${id}`;

const PRODUCT_CATEGORIES = "/product/categories";
export const RESTAURANT_PRODUCT_CATEGORIES = `/restaurant/${PRODUCT_CATEGORIES}`;
export const CREATE_RESTAURANT_PRODUCT_CATEGORIES = `${RESTAURANT_PRODUCT_CATEGORIES}/create`;
export const UPDATE_RESTAURANT_PRODUCT_CATEGORIES = (id) => `${RESTAURANT_PRODUCT_CATEGORIES}/${id}`;
export const GET_RESTAURANT_PRODUCT_CATEGORIES = (id) => `${RESTAURANT_PRODUCT_CATEGORIES}/${id}`;

export const SERVICE_PRODUCT_CATEGORIES = `/restaurant/${PRODUCT_CATEGORIES}`;
export const CREATE_SERVICE_PRODUCT_CATEGORIES = `${CREATE_SERVICE_PRODUCT_CATEGORIES}/create`;
export const UPDATE_SERVICE_PRODUCT_CATEGORIES = (id) => `${CREATE_SERVICE_PRODUCT_CATEGORIES}/${id}`;
export const GET_SERVICE_PRODUCT_CATEGORIES = (id) => `${CREATE_SERVICE_PRODUCT_CATEGORIES}/${id}`;


export const PAYMENTS = "/payments";
export const CREATE_PAYMENT = `${PAYMENTS}/create`;
export const VERIFY_PAYMENT = (id) => `${PAYMENTS}/${id}/verify`;
export const APPROVE_PAYMENT = (id) => `${PAYMENTS}/${id}/approve`;
export const DISAPPROVE_PAYMENT = (id) => `${PAYMENTS}/${id}/reject`;

export const USERS = "/users";
export const CREATE_USER = `${USERS}/create`;
export const USER_PROFILE = (id) => `${USERS}/${id}`;
export const UPDATE_USER = (id) => `${USERS}/${id}`;
export const DEACTIVATE_USER = (id) => `${USERS}/${id}/deactivate`;
export const ACTIVATE_USER = (id) => `${USERS}/${id}/activate`;
export const ACTIVITY_LOGS = `${USERS}/activity-logs`;
export const MODULES = `${ACTIVITY_LOGS}/modules`;

export const ROLES = "/user/roles";