<template>

    <div>

        <TopBar title="Audit Logs" />

        <search-view 
            :has-filter="true"
            :filter-params="filters"
            :is-loading="false"
            :has-search-field="true"
            @submit="runFilter" />

        <logs-table class="mt-4" ref="LogsTable" />

    </div>

   

    
</template>

<script>

    import TopBar from "../../components/TopBar.vue";
    import LogsTable from './components/LogsTable.vue';
    import SearchView from "../../components/SearchView.vue";
    import { UsersService } from '@/services';

    export default {
        name:'logs',
        components: {
            TopBar,
            SearchView,
            LogsTable
        },
        data() {
            return {
                filters: [
                    {name: "User Email", field_name: "email", options:""},
                    {name: "Module", field_name: "module", options:[]},
                ],
            }
        },
      methods: {
          runFilter(filter_params) {
                this.$refs.LogsTable.filter(filter_params);
            },
          async getModules(page = 1) {
                this.current_page = page;
                let { data } = await UsersService.fetchModules();
                this.data = data;
                this.filters[1].options = data.modules;
            },
      },
      mounted() {
        this.getModules();
      }
    };
    
</script>

<style scoped>
    

</style>