<template>

    <div class="w-100 top-bar-container">
        
        <div class="bg-white top-bar d-flex justify-content-between">
            <h5 class="font-weight-bold mt-3 text-capitalize">
                <b-icon 
                    v-if="hasBackButton" 
                    @click="goBack" 
                    class="mr-1 font-weight-bold rounded-lg back_btn" 
                    icon="arrow-left-short" 
                    font-scale="1.2" 
                /> 
                {{ title }}
            </h5>
            <div class="account">
                <b-dropdown class="dropdown" toggle-class="text-decoration-none" variant="none" no-caret menu-class="w-100">
                    <template #button-content>
                        <div class="d-flex justify-content-between">
                            <div class="mr-2 text-right font-weight-bold mt-2" style="line-height:1">{{ `${user.first_name} ${user.last_name}`  }}<br /><small>{{ user_role }}</small></div>
                            <img :src="avatar" width="45" height="45" class="d-block" />
                        </div>
                    </template>
                    <b-dropdown-item class="dropitem" to="profile"><b-icon icon="person" font-scale="1" class="icon" /> Profile</b-dropdown-item>
                    <b-dropdown-item class="dropitem" @click.prevent="logOut"><b-icon icon="power" class="icon" /> Log Out</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

    </div>

</template>

<script>

    import { mapActions, mapGetters } from 'vuex';

    export default {
        name:'topbar',
        props: ['title', 'hasBackButton'],
        methods: {
            ...mapActions({
                logoutAction: "auth/logout"
            }),
            goBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            }, 
            logOut() {
                this.logoutAction().then( () => {
                    this.$router.replace({name: 'login'})
                });
            }
        },
        data() {
            return {
                avatar: require("../assets/icons/avatar.svg")
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/user",
                user_role: "auth/role"
            })
        }
        
    };

</script>

<style scoped>

    .back {
        font-family: 'Circular Std';
        font-weight: 600;
        cursor: pointer;
    }

    .top-bar-container {
        position:fixed;
        top:0; 
        z-index:1; 
        margin-left: -35px;
        padding: 30px 30px 0 35px;
        background-color:rgba(252, 250, 255, 0.90);
    }

    .top-bar {
        width: 82.4%;
        padding:5px 20px 3px 30px;
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
		border-bottom:solid 1px #ebeff7;
        border-radius: 0.428rem;
        transition: all .3s ease,background 0s;
    }


    .back_btn:hover {
        background-color: #ebeff7;
        cursor: pointer;
    }

</style>