<template>

    <b-card class="p-2">
        <b-row>
            <b-col>
                <h5>All Logs</h5>
            </b-col>
        </b-row>

        <b-table small responsive :fields="fields" :items="items" class="mt-4 table-fit">

            <template #cell(user)="data">
                <span class="font-weight-bold">{{ data.item.user.first_name + " " + data.item.user.last_name }}</span>
            </template>

            <template #cell(module)="data">
                <b-badge class="font-weight-bold border text-capitalize" variant="light">{{ data.item.module }}</b-badge>
            </template>

            <template #cell(log)="data">
                <small>{{ data.item.log }}</small>
            </template>

            <template #cell(date)="data">
                <small>{{ data.item.date | moment("DD MMM YYYY, h:mm") }}</small>
            </template>

        </b-table>

    </b-card>
            
</template>

<script>
    import { UsersService } from '@/services';

    export default({
        name:'logs-table',
        methods: {
            filter(filter_params) {
                this.request_params = filter_params;
                this.getLogs();
            },
            async getLogs(page = 1) {
                this.current_page = page;
                let { data } = await UsersService.fetchActivityLogs({page:page, ...this.request_params});
                this.data = data;
                this.items = data.data;
            },
        },
        data: () => {
            return {
                fields: ['user', 'module', 'log', 'date'],
                items: [],
                request_params:{}
            }
        },
        mounted() {
            this.getLogs();
        }
    })
</script>

<style scoped>


</style>