<template>
  <div>
    <edit-product :room="selected_product" @roomSaved="fetchProducts" />
    
    <b-card class="p-2">

      <b-row>
          <b-col>
              <h5 class="font-weight-bold">Product List</h5>
          </b-col>
          <b-col class="text-right">
               <b-col class="text-right">
                  <b-button variant="outline-primary" class="mr-2" to="/room/types">Product Categories</b-button>
                  <b-button variant="primary" v-if="canCreate" @click="selected_room = null" v-b-toggle.edit-room-modal>New Product</b-button>
                </b-col>
          </b-col>
      </b-row>

      <b-table small responsive :fields="type === 'restaurant' ? restaurant_fields : service_fields" :items="items" class="mt-4 table-fit">
        <template #cell(product)="data">
          <div class="font-weight-bold">{{ data.item.name }}</div>
        </template>

        <template #cell(category)="data">
          <div>{{ data.item.category.name }}</div>
        </template>

        <template #cell(price)="data">
          <div>{{ formatMoney(data.item.price) }}</div>
        </template>

        <template #cell(units_available)="data">
          <div>{{ formatNumber(data.item.quantity_available) }}</div>
        </template>

        <template #cell(actions)="data" v-if="canUpdate">
          <b-button-group size="sm">
            <b-button variant="light" class="border text-primary font-weight-bold" @click="selected_product = data.item" v-b-toggle.edit-product-modal>
              <b-icon icon="pencil-fill" font-scale="0.8" shift-v="6"></b-icon> Edit
            </b-button>
          </b-button-group>
        </template>
      </b-table>

    </b-card>

    <b-row v-show="data.total">
      <b-col class="mt-4 font-weight-bold">{{ formatNumber(data.from) + " to " + formatNumber(data.to) + " of " + formatNumber(data.total) }} Products</b-col>
      <b-col><b-pagination v-model="data.current_page" :total-rows="data.total" :per-page="data.per_page" align="right" class="mt-3" @input="fetchProducts"></b-pagination></b-col>
    </b-row>
    
  </div>
</template>

<script>
  import {formatNumber, formatMoney} from '../../../utils/helpers.js';
  import EditProduct from "./EditProduct.vue";
  import { ProductService } from "@/services";
  import { mapGetters } from 'vuex';

  export default {
    name: "products-table",
    props: ['type'],
    components: {
      EditProduct,
    },
    data: () => {
      return {
        service_fields: ["product", "category", "price", "actions"],
        restaurant_fields: ["product", "category", "price", "units_available", "actions"],
        data: {},
        items: [],
        request_params: {},
        current_page: 0,
        selected_product: {},
      };
    },
    methods: {
      formatNumber,
      formatMoney,
      filter(filter_params) {
          this.request_params = filter_params;
          this.fetchProducts(1);
      },
      async fetchProducts(page=1) {
        this.current_page = page;
        let { data } = this.type === 'restaurant' ?
                await ProductService.fetchRestaurantProducts({page:page, ...this.request_params}) :
                await ProductService.fetchServiceProducts({page:page, ...this.request_params});

        this.data = data;
        console.log(data.data);
        this.items = data.data;
      },
      canCreate() {
        if(this.type === 'restaurant') {
          return this.user.permissions.includes('create_restaurant_product');
        } else {
          return this.user.permissions.includes('create_service_product');
        }
      },
      canUpdate() {
        if(this.type === 'restaurant') {
          return this.user.permissions.includes('update_restaurant_product');
        } else {
          return this.user.permissions.includes('update_service_product');
        }
      }
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        })
    },
    mounted() {
      this.fetchProducts();
    }
  };
</script>

<style scoped></style>
