<template>
  <b-sidebar
    id="sidebar-edit-booking"
    title="New Booking"
    :backdrop-variant="variant"
    backdrop
    shadow
    right
    body-class="sidebar"
    ref="edit_booking_sidebar"
  >
    <div class="px-4 py-2">

      <b-form-group label="Check In Date" label-for="checkin">
        <b-form-datepicker
          v-model="form.check_in_date"
          id="datepicker-lg"
          size="md"
          locale="en"
          @input="calculateAmount"
          :min="min_checkin"
        ></b-form-datepicker>
         <small class="text-danger" v-if="errors.check_in_date">{{ errors.check_in_date[0] }}</small>
      </b-form-group>

      <b-form-group label="Check Out Date" label-for="checkout">
        <b-form-datepicker
          v-model="form.check_out_date"
          id="datepicker-lg"
          size="md"
          locale="en"
          @input="calculateAmount"
          :min="min_checkout"
        ></b-form-datepicker>
        <small class="text-danger" v-if="errors.check_out_date">{{ errors.check_out_date[0] }}</small>
      </b-form-group>

      <b-form-group label="Room Type" label-for="room-type">
        <b-form-select
          id="room-type"
          v-model="form.room_type_id"
          :options="room_types"
          value-field="id"
          text-field="name"
          @change="calculateAmount"
        ></b-form-select>
        <small class="text-danger" v-if="errors.room_type_id">{{ errors.room_type_id[0] }}</small>
        <small v-if="selected_room_type.rate != undefined"><b>{{ formatMoney(selected_room_type.rate) }} per night</b></small>
      </b-form-group>

      <b-form-group label="Guests" label-for="guests">

        <vue-typeahead-bootstrap
          v-model="query"
          :ieCloseFix="false"
          :data="guests"
          :serializer="item => item.first_name + ' ' + item.last_name"
          @hit="selectGuest"
          placeholder="Search Guests"
          @input="lookupGuest"
          background-variant="light"
        />

        <div class="mt-2">
          <b-list-group>
            <b-list-group-item class="border p-2 mb-1 d-flex bg-white justify-content-between" v-for="guest in selected_guests" v-bind:key="guest.id">
              <span><b-button class="btn btn-sm btn-light pl-2 pr-2 d-inline text-danger" @click="removeGuest(guest)">x</b-button> <span class="mt-2">{{ guest.first_name + " " + guest.last_name }}</span></span>
              <small class="text-primary mt-2" v-if="guest.id === form.principal_guest_id"><b><b-icon icon="check2-square" class="icon" /> Principal Guest</b></small>
              <small class="text-primary mt-2" v-if="guest.id !== form.principal_guest_id"><b><span class="pointer" @click="makePrincipal(guest)">Make Principal</span></b></small>
            </b-list-group-item>
          </b-list-group>
        </div>

        <small class="text-danger" v-if="errors.guests">{{ errors.guests[0] }}</small>
        <small class="text-danger d-block" v-if="errors.principal_guest_id">{{ errors.principal_guest_id[0] }}</small>
      </b-form-group>

      <label>Total Amount</label>
      <h3 class="mb-0"><b>{{ formatMoney(amount) }}</b></h3>

      <hr class="mb-3" />

      <stateful-button
        variant="primary"
        :loading="is_loading"
        loadingText="Loading..."
        text="Submit"
        type="button"
        elementClass="pl-5 pr-5 mr-3 w-100"
        @click="saveBooking" />

    </div>

    <div class="mt-4 p-3">
      <h6 class="font-weight-bold border-bottom pb-1">Assign Room</h6>

      <b-form-group label="Room" label-for="room" class="mb-4">
        <div class="d-flex">
            <div class="w-75">
                <b-form-select id="room" v-model="assign_form.room_id" value-field="id" text-field="name" :options="rooms"></b-form-select>
            </div>
            <b-button variant="dark" class="ml-2 w-25" @click="assignRoom">Assign</b-button>
        </div>
        <small class="text-danger" v-if="assign_form_errors.room_id">{{ assign_form_errors.room_id[0] }}</small>
      </b-form-group>

    </div>
  </b-sidebar>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import { BookingService, RoomService, GuestService } from "@/services";
import StatefulButton from "./actions/StatefulButton.vue";
import { formatMoney } from "@/utils/helpers.js";
import { createToast } from '@/utils/helpers';

export default {
  name: "edit-booking",
  props: {
    booking: {
      type: Object,
      default: null,
    },
    closeOnSave: {
        type: Boolean,
        default: false
    }
  },
  components: {
    StatefulButton,
    VueTypeaheadBootstrap
  },
  data() {
    return {
      form: {
        room_type_id: 0,
        check_in_date: "",
        check_out_date: "",
        guests: "",
        principal_guest_id: "",
      },
      assign_form: {
        room_id: "",
      },
      assign_form_errors: [],
      rooms: [],
      guests: [],
      query: '',
      errors: [],
      variant: "dark",
      room_types: [],
      is_loading: false,
      amount:0,
      selected_guests: [],
      selected_room_type:{},
      min_checkin: this.$moment().format("YYYY-MM-DD"),
      min_checkout: this.$moment().add(1, 'days').format("YYYY-MM-DD"),
    };
  },
  methods: {
    formatMoney,
    on_submit() {
      //
    },
    calculateAmount() {
      let room_type = this.room_types.find(type => type.id == this.form.room_type_id);
      let start_date = this.$moment(this.form.check_in_date);
      let end_date = this.$moment(this.form.check_out_date);

      if(room_type != undefined && start_date != undefined && end_date != undefined) {
        let days = end_date.diff(start_date, 'days');
        this.amount = room_type.rate * days;
        this.selected_room_type = room_type;
      }
    },
    selectGuest(guest) {
      console.log(guest)
      console.log(this.selected_guests)
      if(this.selected_guests.find(item => item.id === guest.id) === undefined) {
        this.selected_guests[this.selected_guests.length] = guest

        if(this.selected_guests.length == 1 && this.form.principal_guest_id == "") {
          this.form.principal_guest_id = guest.id;
        }
      }
      this.query = ""
    },
    async saveBooking() {
      try {
        this.is_loading = true;
        this.form.guests = this.selected_guests.map(guest => guest.id);
        
        let data = this.booking.id === undefined ?
                  await BookingService.createBooking(this.form) :
                  await BookingService.updateBooking(this.booking.id, this.form)

        console.log(this.booking.id)

        this.is_loading = false;

        createToast(this, data.message, data.success);

         if(this.closeOnSave) {
            this.$refs.edit_booking_sidebar.hide();
         }

         this.$emit('bookingSaved');

      } catch (err) {
        this.is_loading = false;
        this.errors = err;
      }
    },
    async assignRoom() {
      try {
        let data = await BookingService.assignRoom(this.booking.id, this.assign_form)

        createToast(this, data.message, data.success);

         if(this.closeOnSave) {
            this.$refs.edit_booking_sidebar.hide();
         }

         this.$emit('bookingSaved');

      } catch (err) {
        this.is_loading = false;
        this.assign_form_errors = err;
      }
    },
    reset() {
      this.form = {
        room_type_id: 0,
        check_in_date: "",
        check_out_date: "",
        guests: [],
        principal_guest_id: ""
      }

      this.amount = 0;
      this.selected_room_type = {};
      this.selected_guests = [];
    },
    async fetchRoomTypes() {
      let {data} = await RoomService.fetchRoomTypes();
      this.room_types = data.room_types
    },
    async lookupGuest(){
        if(this.query.length > 3) {
          let { data } = await GuestService.getGuests({search: this.query});
          this.guests = data.data;
        }
    },
    removeGuest(item) {
      this.selected_guests = this.selected_guests.filter(function(guest){ 
          return item.id !== guest.id;
      });

      if(this.form.principal_guest_id == item.id) {
        this.form.principal_guest_id = "";
      }
    },
    makePrincipal(item) {
      this.form.principal_guest_id = item.id;
    },
    async fetchAvailableRooms() {
      let {data} = await BookingService.fetchAvailableRooms(this.form.id);
      this.rooms = data.rooms;
    },
  },
  created() {
    this.fetchRoomTypes();
  },
  watch: {
    booking: {
      handler(val){
        this.reset();
        if(val.id !== undefined) {
          this.form = val;
          this.selected_guests = val.guests;
          this.calculateAmount();
          this.fetchAvailableRooms();
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
  
</style>
