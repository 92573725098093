import axios from "axios";
import router from "./router";

export const http = {
    install(Vue) {
        Vue.prototype.$http = Vue.http = axios.create({
            baseURL: process.env.VUE_APP_API_BASE,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });
    }
};

export { router };