<template>
    <b-sidebar id="edit-room-modal" ref="edit_room_modal" backdrop shadow right body-class="sidebar" :title="!room ? 'Create Room' : 'Edit Room'" :ok="on_submit">
        <div class="p-4">
            <b-form-group label="Room Name" label-for="name">
                <b-form-input id="name" v-model="form.name"  />
                <small class="text-danger" v-if="errors.name">{{ errors.name[0] }}</small>
            </b-form-group>
          
            <b-form-group label="Room Type" label-for="room-type" class="mb-4">
                <div class="d-flex">
                    <div class="w-75">
                        <b-form-select id="room-type" v-model="form.room_type_id" value-field="id" text-field="name" :options="room_types"></b-form-select>
                        <small class="text-danger" v-if="errors.room_type_id">{{ errors.room_type_id[0] }}</small>
                    </div>
                    <b-button variant="dark" class="ml-2 w-25" to="/room/types/create">New</b-button>
                </div>
            </b-form-group>

            <hr />

            <b-button @click="on_submit" variant="primary" class="w-100">Submit</b-button>
        </div>
    </b-sidebar>
</template>

<script>
import { RoomService } from "@/services";
import { createToast } from '@/utils/helpers';

export default({
    name:'edit-room',
    props: ['room'],
    data() {
        return {
            title:"Room",
            room_types: [],
            form: {
                name: '',
                room_type_id: ''
            },
            loading: false,
            errors: [],
        }
    },
    methods: {
        async on_submit(e) {
            e.preventDefault();
            try {
                this.is_loading = true;
                
                let data = this.form.id === undefined ?
                        await RoomService.createRoom(this.form) :
                        await RoomService.updateRoom(this.form.id, this.form)

                this.is_loading = false;

                createToast(this, data.message, data.success);

                this.$refs.edit_room_modal.hide();
                this.reset();
                
                this.$emit('roomSaved');
            } catch (err) {
                this.is_loading = false;
                this.errors = err;
            }
        },
        async fetchRoomTypes() {
            let {data} = await RoomService.fetchRoomTypes();
            this.room_types = data.room_types;
        },
        reset() {
            this.form = {
                name: '',
                room_type_id: ''
            }
        }
    },
    mounted: function() {
        this.fetchRoomTypes();
    },
    watch: {
        room: {
            handler(val){
                if(val != null && val != undefined) {
                    this.form.name = val.name;
                    this.form.id = val.id;
                    this.form.room_type_id = val.room_type.id;
                }
            },
            deep: true
        }
    }
})
</script>

<style scoped></style>
