<template>
  <b-sidebar
    id="payment-form-sidebar"
    title="New Payment"
    :backdrop-variant="variant"
    backdrop
    shadow
    right
    body-class="sidebar"
    ref="payment_form_sidebar"
  >
  
    <div class="px-4 py-2">

      <label>Amount</label>
      <h3 class="mb-3"><b>{{ formatMoney(form.amount) }}</b></h3>

      <hr class="mb-3" />

      <b-form-group label="Payment Channel" label-for="channel">
        <b-form-select
          id="channel"
          v-model="form.channel"
          :options="channels"
          value-field="value"
          text-field="name"
        ></b-form-select>
        <small class="text-danger" v-if="errors.channel">{{ errors.channel[0] }}</small>
      </b-form-group>

      <b-form-group label="Payment Reference" label-for="reference" v-show="form.channel == 'pos' || form.channel === 'bank_transfer'">
        <b-form-input
          id="reference"
          v-model="form.reference"
        ></b-form-input>
        <small class="text-danger" v-if="errors.reference">{{ errors.reference[0] }}</small>
      </b-form-group>

      <b-form-group label="Payment Proof" label-for="payment_proof" v-show="form.channel === 'bank_transfer'">
        <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/*"
            ></b-form-file>
            <small class="text-danger" v-if="errors.proof">{{ errors.proof[0] }}</small>
      </b-form-group>

      <hr class="mb-3 mt-3" />

      <div>
        <stateful-button
          variant="primary"
          :loading="is_loading"
          loadingText="Loading..."
          text="Submit"
          type="button"
          elementClass="pl-5 pr-5 mr-3 w-100"
          @click="submit"
          background-variant="primary"
          :background-variant-resolver="(user) => ((user.id % 2) == 0) ? 'light':'dark'"
        ></stateful-button>

      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { PaymentService } from "@/services";
import StatefulButton from "./actions/StatefulButton.vue";
import { formatMoney } from "@/utils/helpers.js";
import { createToast } from '@/utils/helpers';

export default {
  name: "payment-form",
  props: {
    payment_entity: {
      type: Object,
      default: null
    },
    closeOnSave: {
        type: Boolean,
        default: false
    }
  },
  components: {
    StatefulButton,
  },
  data() {
    return {
      form: {
        user_id: "",
        amount: 0,
        entity: "",
        entity_id: "",
        channel: null,
        reference: "",
        proof: ""
      },
      channels: [
        { value: null, name: 'Please select an option' },
        {value: 'bank_transfer', name: "Bank Transfer"},
        {value: 'cash', name: "Cash"},
        {value: 'pos', name: "POS"},
      ],
      file1: null,
      errors: [],
      variant: "dark",
      is_loading: false,
    };
  },
  methods: {
    formatMoney,
    async submit() {
      try {
        this.is_loading = true;
        
        let data = await PaymentService.createPayment(this.form)

        this.is_loading = false;

        createToast(this, data.message, data.success);

         if(this.closeOnSave) {
            this.$refs.payment_form_sidebar.hide();
         }

         this.$emit('paymentSaved');

      } catch (err) {
        this.is_loading = false;
        this.errors = err;
      }
    },
    reset() {
      this.form = {
        user_id: "",
        amount: 0,
        entity: "",
        entity_id: "",
        channel: "",
        reference: "",
        proof: ""
      }
    },
  },
  watch: {
    payment_entity: {
      handler(val){
        this.reset();
        this.form.user_id = val.user_id;
        this.form.entity = val.entity;
        this.form.entity_id = val.entity_id;
        this.form.amount = val.amount;
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
